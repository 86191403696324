.content-tokenes-investment {
  display: flex;
  flex-direction: column;
}
.investment-container {
  margin: 1.5em 0;
  z-index: 1;
  position: relative;
  &__item {
    margin-bottom: 0;
  }

  .no-token-rewards {
    order: 1;
  }
  .reward-token {
    order: 2;
  }
  .lp-token {
    order: 3;
  }
  &__child {
    margin-bottom: 1.7em;
    border: 1px solid $color-extra-grey-2;
    // &:last-child {
    //   .investment-container__item {
    //     border-bottom: none;
    //   }
    // }
  }
  &__investment-name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8em;
    padding: 0 0.3em;
    align-items: flex-end;
    @media screen and (max-width: $lg) {
      align-items: center;
    }
    &__left {
      display: flex;
      align-items: center;
      @media screen and (max-width: $lg) {
        margin: 0.7em 0;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        div.u-display-flex.u-align-items-center:last-child {
          margin-top: 0.5em;
        }
      }
      &--image-symbol {
        width: 33px;
        // height: 33px;
        // object-fit: cover;
      }
      &--symbol {
        margin: 0 0.8em;
        font-size: 24px !important;
        font-family: "Roboto Medium" !important;
      }
      &--amount {
        margin-right: 1.3em;
        font-size: 12px !important;
        font-family: "Roboto" !important;
      }
      &--pair {
        display: flex;
        align-items: center;
        img {
          width: 16px;
          &:last-child {
            left: -5px;
            position: relative;
          }
        }
      }
    }
    &__right {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      .total-price {
        text-align: right;
        font-size: 12px;
        margin-right: 0.5em;
      }
      .total-amount {
        display: flex;
        align-items: flex-end;
      }
      .total-percentage {
        display: flex;
        align-items: flex-end;
      }
      .content-message-total-1 {
        display: none;
        position: relative;
        cursor: pointer;
        svg {
          color: #dfdfdf;
          position: relative;
          top: -10px;
          font-size: 13px;
        }
        div {
          top: -23px;
          left: 20px;
          background: #bebebe;
          padding: 5px 7px;
          border-radius: 5px;
          font-size: 12px;
          position: absolute;
          width: max-content;
          color: $color-contrast;
          line-height: 16px;
          transform: scaleX(0) scaleY(0);
          transform-origin: left 70%;
        }
        @media screen and (max-width: $lg) {
          div {
            right: 17px;
            left: inherit;
            top: -47px;
          }
        }
      }
      .content-message-total-1:hover > div {
        transform: scaleX(1) scaleY(1);
      }
      .content-message-total-2 {
        display: none;
        position: relative;
        cursor: pointer;
        svg {
          color: #dfdfdf;
          font-size: 13px;
        }
        div {
          top: 0;
          color: $color-contrast;
          left: 20px;
          background: #bebebe;
          padding: 5px 7px;
          border-radius: 5px;
          font-size: 12px;
          position: absolute;
          width: max-content;
          line-height: 16px;
          transform: scaleX(0) scaleY(0);
          transform-origin: left 70%;
        }
        @media screen and (max-width: $lg) {
          div {
            top: inherit;
            right: 0;
            left: inherit;
            bottom: -28px;
          }
        }
      }
      .content-message-total-2:hover > div {
        transform: scaleX(1) scaleY(1);
      }
      .total-usd {
        position: relative;
        bottom: 3px;
      }
      .total-prifit {
        text-align: right;
        font-size: 24px;
        font-family: "Roboto Medium";
        margin-right: 3px;
        span {
          color: #999999 !important;
        }
      }
      .total-prifit-percentage {
        text-align: right;
        font-size: 12px;
        margin-right: 0.2em;
      }
      @media screen and (max-width: $lg) {
        align-items: flex-end;
        flex-direction: column;
      }
    }
  }
  &__card {
    height: 100%;
    padding: 0.9em 1em;
    background: #fafafa;
    &--title {
      margin-bottom: 0.5em;
      font-size: 14px !important;
      color: #666666 !important;
      font-family: "Roboto Medium" !important;
    }
    &--amount {
      display: flex;
      align-items: baseline;
      position: relative;
      margin-bottom: 0.2em;
      font-size: 22px !important;
      color: $color-dark !important;
      font-family: "Roboto" !important;
      text-transform: uppercase;
      @media screen and (max-width: $lg) {
        justify-content: center;
      }
      small {
        margin-left: 0.2em;
        font-size: 12px !important;
        color: $color-extra-grey !important;
        font-family: "Roboto Medium" !important;
      }
      .tooltip-amount {
        position: absolute;
        top: -25px;
        font-size: 0.9rem;
        padding: 0.2em 0.5em;
        border-radius: 5px;
        width: max-content;
        transform: scale(0);
        transition: 0.2s ease all;
        transform-origin: bottom center;
        background: $color-extra-grey;
      }
    }

    .lp-tokens {
      font-size: 0.8rem;
      color: $color-extra-grey;
      span {
        b {
          color: $color-dark;
        }
      }
    }

    &--amount-usd {
      margin-bottom: 1em;
      font-size: 12px !important;
      color: $color-extra-grey !important;
      font-family: "Roboto" !important;
    }
    .btn-investment-card {
      width: auto;
      height: 27px;
      margin-bottom: 10px;
      width: auto;
    }

    &--unvested {
      margin-top: 1em;
      font-size: 12px !important;
      color: #666666 !important;
      font-family: "Roboto Medium" !important;
    }
    &--unvested-value {
      margin-top: 0.2em;
      font-size: 12px !important;
      color: $color-extra-grey !important;
    }
  }
}

.investment-cards {
  margin-top: 1.3em;
  padding: 1.5em 1em;
  background: #fafafa;
  padding-bottom: 1em;
  @media screen and (max-width: $lg) {
    padding-top: 1em;
    padding: 1em 0.5em;
  }
  &__card {
    padding: 0.7em 2em 0 2em;
    padding-right: 0;
    margin-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @media screen and (max-width: $lg) {
      margin: 0;
      padding: 0 0.5em;
    }
  }
  &__card-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .total-balance-amount {
      display: flex;
      align-items: center;
      width: 60%;
      flex-wrap: wrap;
      .text-profit {
        color: $color-dark;
        width: 72%;
        justify-content: right;
      }
      span {
        font-size: 12px;
      }
    }
    p {
      width: 40%;
      @media screen and (max-width: 420px) {
        font-size: 14px;
      }
    }
    .text-profit {
      margin-right: 0.2em;
      font-size: 24px !important;
      font-family: "Roboto Medium";

      display: flex;
      align-items: center;
      @media screen and (max-width: 420px) {
        font-size: 22px !important;
      }
      span {
        font-size: 12px;
        margin-left: 0.5em;
        color: $color-extra-grey;
      }
    }
  }
  &__chart {
    padding-left: 2em;
    margin-bottom: 1em;
    position: relative;
    @media screen and (max-width: $lg) {
      display: none;
    }
    &--pie {
      width: 110px;
      height: 110px;
      transform: rotate(-90deg);
    }

    display: flex;
    align-items: center;
    ul {
      margin-left: 1.2em;
      li {
        font-size: 15px;
        margin: 0.3em 0;
        font-family: "Roboto Medium";
      }
    }
  }
}

.upcoming-actions {
  margin-top: 2.5em;
  &__title {
    padding-left: 12px !important;
    &::before {
      left: 0 !important;
    }
  }

  &__card {
    margin-top: 1em;
    border: 1px solid #c5c5c5;
    padding: 0.5em 0.5em 0;
    &--item {
      margin: 0 0.8em;
      padding: 1em 0 1.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #c5c5c5;
      &:first-child {
        border-top: none;
      }
    }
    &--date {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1em;
      align-items: center;
      background: #fafafa;
      color: $color-extra-grey;
      margin-right: 1.5em;
      span {
        width: 30px;
        text-align: center;
      }
    }
    &--symbol {
      margin-top: 12px;
      img {
        width: 20px;
      }
      span {
        margin-left: 0.7em;
        font-size: 18px;
        color: $color-dark;
        font-family: "Roboto Medium";
      }
    }
    &--token {
      .text-percentage-investors {
        margin-top: 0.5em;
        display: block;
        font-size: 15px;
        color: $color-extra-grey;
        font-family: "Roboto";
      }
    }
    &--actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        width: auto;
      }
      span {
        font-size: 10px;
        margin-top: 0.2em;
        font-family: "Roboto";
        color: $color-extra-grey;
      }
    }
  }
  // .margin-none-opportunities{
  //   margin-top: 0;

  //   padding-bottom: 1em;
  // }
}

.custom-section-card {
  padding: 0 0.5em;
  .upcoming-actions__card--item {
    padding: 1.5em 0 1.5em;
  }
}

.display-for-smartphone {
  display: none;
}

.eyelash-container {
  display: none;
}

.breakpoint-higher-1400 {
  display: block;
}
.breakpoint-less-1400 {
  display: none;
}
.icon-collapse-proyect-smartphone {
  display: none !important;
}
.icon-collapse-proyect {
  display: block;
}
.value-to-claim {
  display: none;
}
@media screen and (max-width: $xxl) {
  .breakpoint-higher-1400 {
    display: none;
  }
  .breakpoint-less-1400 {
    display: block;
    text-transform: uppercase;
  }
}

.padding-smartphone {
  padding-bottom: 3em;
}
@media screen and (max-width: $lg) {
  .icon-collapse-proyect-smartphone {
    display: block !important;
  }
  .icon-collapse-proyect {
    display: none;
  }
  .display-for-desktop {
    display: none;
  }
  .display-for-smartphone {
    display: block;
  }

  .investment-container__item {
    .col-lg-4 {
      margin-bottom: 0.5em;
    }
  }
  .eyelash-container {
    display: flex;
    padding-top: 1em;
    margin: 0 0.3em;
    border-top: 3px solid #fafafa;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__card {
      padding-bottom: 0.5em;
      border-bottom: 3px solid $color-primary;
    }

    .investment-container__card--title {
      margin-bottom: 0.3em;
      text-align: center;
    }
    .investment-container__card--amount {
      text-align: center;
      font-size: 10px !important;
      span {
        font-size: 10px !important;
      }
    }
  }
  .investment-container__card__amount-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button.btn-investment-card {
      width: auto !important;
    }
  }
  .investment-container__card--amount {
    font-size: 16px !important;
  }
  .u-margin-bottom-none-rp {
    margin-bottom: 1em !important;
  }
  .value-to-claim {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__claim {
      display: flex;
      margin-bottom: 1em;
      font-family: "Roboto Medium";
      font-size: 12px;
      align-items: flex-end;
      a {
        font-size: 15px;
        margin: 0 0.2em;
        color: $color-primary;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &__total-claimed {
      font-size: 12px;
      color: $color-extra-grey;
      span {
        font-family: "Roboto Medium";
      }
    }
  }
  .padding-smartphone {
    padding: 0 0.9em;
  }
}
.display-only-desktop {
  display: block;
}
.table-history-smartphone {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    font-size: 11px;
    flex-direction: column;
    &__row {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5em 0;
    }
    &__col1 {
      display: flex;
      flex-direction: column;
      width: 20%;
    }
    &__col2 {
      display: flex;
      flex-direction: column;
      width: 55%;
      text-align: right;
      padding: 0 1em;
      a {
        font-size: 11px;
        word-break: break-word;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $color-dark;
        svg {
          color: $color-primary;
        }
      }
    }
    &__col3 {
      text-transform: capitalize;
      text-align: center;
      display: flex;
      flex-direction: column;
      width: 25%;
    }
  }
}

.display-only-smartphone {
  display: none;
}
@media screen and (max-width: 768px) {
  .display-only-desktop {
    display: none;
  }
  .display-only-smartphone {
    display: block;
  }
}

.border-bottom-gray {
  border-color: #c5c5c5;
}

.amount-usds {
  left: 7px;
  position: absolute;
  color: #999999 !important;
  font-family: "Roboto" !important;
}

.input-usd-token {
  input {
    padding-left: 2em !important;
  }
}

.content-cards {
  display: flex;
  flex-wrap: wrap;
  &__card {
    margin: 0 0.3em;
    width: calc((100% / 3) - 0.6em);
  }
  @media screen and (max-width: $lg) {
    width: 100%;
    &__card {
      width: 100%;
      margin-top: 0.5em;
    }
  }
  &__full-card {
    width: 100%;
  }
}
.pool-liquidity {
  display: flex;
  flex-wrap: wrap;
  &__value {
    width: 33%;
  }
  &__due-lp-tokens {
    padding: 0 1.3em;
    width: 37%;
    &--fiat {
      text-align: right;
    }
    &--token {
      color: $color-extra-grey;
      font-size: 12px;
      margin: 0.5em;
      margin-top: 0.3em;
      justify-content: space-around;
      img {
        width: 20px;
      }
      display: flex;
      align-items: center;
    }
    &--order {
      span:first-child {
        margin-left: 0.5em;
        font-family: "Roboto Medium";
        margin-right: 0.4em;
      }
      span {
      }
    }
    .content-token {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__token-1 {
        display: flex;
        align-items: center;
      }
    }
  }
  &--order {
  }
  .btns-tokens {
    div {
      padding: 0 0.2em;
      margin-right: 5px;
      @media screen and (max-width: 1380px) {
        padding-left: 0.4em;
      }
      @media screen and (max-width: 1050px) {
        padding-left: 0.6em;
      }
    }
    button {
      width: 100% !important;
    }
    width: 30%;
  }
  @media screen and (max-width: $lg) {
    width: 100%;
    justify-content: space-between;
    .btns-tokens {
      width: auto;
    }
    &__value {
      width: auto;
    }
    &__due-lp-tokens {
      width: auto;
    }
  }
}
.btns-tokens {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: $lg) {
    justify-content: center;
  }
}

.btns-tokens {
  width: 100%;
  div {
    padding-right: 5px;
    flex-grow: 1;
    button {
      width: 100% !important;
      padding: 0 1em;
    }

    @media screen and (max-width: $lg) {
      width: auto !important;
      flex-grow: unset;
      button.btn-investment-card {
        width: auto !important;
      }
    }
  }
}

.show-all-numbers:hover {
  > .show-all-numbers__all-number {
    transform: scaleY(1);
  }
}
.show-all-numbers {
  position: relative;
  transition: 0.1s ease all;
  text-transform: uppercase;
  font-family: "Roboto" !important;
}
.show-all-numbers__all-number {
  transition: all;
  width: max-content;
  left: 0;
  top: -26px;
  font-size: 0.8rem;
  position: absolute;
  padding: 0.3em 0.7em 0.25em;
  transform: scaleY(0);
  transform-origin: 50% 100%;
  color: white;
  background: rgba(89, 87, 87, 0.95);
}

.investment-container__item:first-child {
  hr.hr-separator {
    display: none;
  }
}

.text-info-tokens {
  font-size: 10px !important;
  font-family: "Roboto Medium" !important;
  color: $color-extra-grey !important;
  position: relative;
  top: -10px;
}

.investment-container__child:nth-child(1) {
  .investment-container__item:nth-child(1) {
    .content-message-total-2,
    .content-message-total-1 {
      display: block;
    }
  }
}
