.home-page {
  background: #faf5ff;
  width: 100%;
  height: 100vh;
  padding-top: 5em;
  position: relative;
  top: -72px;
  right: 0;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  &__title {
    font-size: 36px;
    margin-bottom: 1.8em;
    color: $color-primary;
    line-height: 1.7em;
    font-family: "Roboto Medium";
  }

  &__description {
    color: $color-dark;
    font-size: 18px;
    margin-bottom: 85px;
    width: 620px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5em;
  }
  @media screen and(max-width: $lg) {
    top: -48px;

    .logo-home-page {
      width: 180px;
    }
    &__description {
      font-size: 18px;
      width: $smartphone;
    }
  }
  @media screen and(max-width: 768px) {
    height: 100vh;
    &__title {
      margin-bottom: 40px;
    }
    &__description {
      width: 420px;
      font-size: 16px;
      margin-bottom: 50px;
    }
  }
  .home-only-smartphone {
    display: none;
  }
  @media screen and(max-width: 540px) {
    .home-only-desktop {
      display: none;
    }
    .home-only-smartphone {
      display: block;
    }
    height: 100vh;
    .logo-home-page {
      margin-left: 20px;
      margin-bottom: 2.5em;
    }
    top: -48px;
    &__title {
      margin-left: 20px;
      font-size: 34px;
      text-align: left;
      margin-bottom: 3em !important;
    }
    &__description {
      padding: 0 3em;
      font-size: 14px;
    }
  }
  @media screen and(max-width: 420px) {
    &__description {
      width: auto;
    }
    .logo-home-page {
      margin-left: 10px;
    }
    &__title {
      margin-left: 10px;
      font-size: 30px;
    }
  }
}

.hassle-section {
  margin-top: 3em;
  img {
    width: 100%;
  }
  &__description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 3em;
    &--title {
      font-family: "Roboto Medium";
      font-size: 36px;
      color: $color-primary;
      margin-bottom: 1.2em;
    }
    &--text {
      font-size: 18px;
      color: $color-dark;
      line-height: 1.8em;
    }
    @media screen and(max-width: $lg) {
      &--title {
        font-size: 25px;
      }
      &--text {
        font-size: 16px;
      }
    }
  }
  @media screen and(max-width: $lg) {
    &--title {
      font-size: 25px;
    }
    &--text {
      font-size: 16px;
    }
  }
  @media screen and(max-width: 768px) {
    // margin-top: 60vh;
    text-align: center;
    &__description {
      &--text {
        margin-bottom: 2em;
      }
    }
  }
  @media screen and(max-width: 767px) {
    flex-direction: column-reverse;
    padding: 0 0.5em;
    &__description {
      padding-left: 0;
    }
  }
}

.dao-section {
  padding: 8em 1em;
  margin-top: 9em;
  background: #faf5ff;
  img {
    width: 82%;
  }
  &__description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 3em;
    &--title {
      font-family: "Roboto Medium";
      font-size: 36px;
      color: $color-primary;
      margin-bottom: 1em;
    }
    &--text {
      width: 510px;
      font-size: 18px;
      color: $color-dark;
      line-height: 1.8em;
    }
    @media screen and(max-width: $lg) {
      &--title {
        font-size: 24px;
      }
      &--text {
        font-size: 14px;
        width: auto;
        .btn-home-learn-more {
          width: auto !important;
          height: 45px !important;
          font-size: 14px !important;
          @media screen and(max-width: 768px) {
            svg {
              display: none;
            }
          }
        }
      }
    }
  }
  @media screen and(max-width: 768px) {
    &__description {
      &--title {
        padding: 0 2em;
      }
      &--text {
        font-size: 14px;
        width: auto;
        .btn-home-learn-more {
          height: 33px !important;
        }
      }
    }
    text-align: center;
    margin-top: 8em;
    &__description {
      margin-bottom: 5em;
    }
  }
  @media screen and(max-width: 767px) {
    &__description {
      padding-left: 0;
      padding: 0 0.5em;
    }
  }
}

.u-background-none {
  header {
    background: none;
  }
}

.home-change-banner {
  height: 100vh;
  footer {
    margin-top: 3.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    a {
      color: $color-primary;
    }
    svg {
      font-size: 27px;
      margin-bottom: 1em;
    }
    p {
      font-size: 15px;
      color: $color-dark;
      margin-bottom: 0.5em;
    }
  }
}

.home-page-primary {
  height: 100vh;
  background: $color-primary;
  button {
    background: none;
    margin: 2em auto 0 auto;
    border: 1px solid $color-contrast;
    &:hover {
      background: none;
    }
  }
  img {
    margin-top: 1em;
    width: 200px;
    filter: invert(1);
  }
  p {
    font-size: 95px;
    margin-top: 30px;
    line-height: 1.3em;
    margin-bottom: 0.5em;
    color: $color-contrast;
    font-family: "Roboto Medium";
    @media screen and (max-width: $xxl) {
      margin-top: 0.5em;
      font-size: 90px;
    }
    @media screen and (max-width: $lg) {
      margin-top: 1em;
      font-size: 70px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 0.5em;
      font-size: 70px;
    }
    @media screen and (max-width: $smartphone) {
      margin-top: 0.5em;
      font-size: 70px;
    }
    @media screen and (max-width: 480px) {
      margin-top: 0.5em;
      font-size: 65px;
    }
    @media screen and (max-width: 375px) {
      margin-top: 0.5em;
      font-size: 50px;
    }
  }
}

.nft-group-transfers {
  display: inline-block;
  position: relative;
  a {
    width: 100px;
    height: 100px;
    margin: 0 5px;
    display: inline-block;
  }
  a:first-child {
    margin-left: 0;
  }
  span{
    position: absolute;
    top: 45%;
    right: -15px;
    transform: translateY(-50%);
  }
}
