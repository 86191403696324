.modal-content {
  padding-right: 50px;
  padding-top: 50px;
  padding-left: 50px;
  padding-bottom: 35px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 95vh;
  // min-height: 400px;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // align-items: center;
  // justify-content: space-around;
  width: 100%;
  &__title-modal {
    margin: auto;
    display: flex;
    font-size: 22px;
    // font-weight: bold;
    text-align: center;
    align-items: center;
    color: $color-primary;
    font-family: "Roboto Medium";
    justify-content: center;
    text-transform: uppercase;
    position: relative;
    svg {
      font-size: 1.5em;
      position: absolute;
      left: 10px;
    }
    background: none !important;
  }

  .color-text-secondary {
    background: $color-secondary;
  }
  &__text-title {
    margin: 0.5em 1em;
    text-align: center !important;
    font-size: 30px !important;
    font-weight: "Roboto Medium";
    span {
      font-size: 1rem;
      margin-left: 0.3em;
    }
  }
  &__row {
    margin-top: 2em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 42px;
    }
    div {
      margin-left: 1em;
    }
  }
  &__description {
    line-height: 2em;
    font-size: 15px !important;
  }
  &__butttons {
    margin-top: 2em;
    display: flex;
    justify-content: center;

    button:first-child {
      background: none !important;
      border: 1px solid $color-primary;
      color: $color-primary;
    }
    button.btn-stake-cancel {
      border: 1px solid $color-secondary;
      color: $color-secondary;
    }
    button {
      margin: 0 0.5em;
      padding: 0px 1.7em !important;
      svg {
        right: 0.4rem !important;
        font-size: 2.2rem !important;
      }
    }
  }
  &__balance {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    b {
      font-size: 20px;
      margin: 0 0.4em;
    }
    .price-balance {
      margin-left: 0.3em;
      font-size: 2em;
      span {
        font-size: 1rem;
        margin-left: 0.3em;
      }
    }
  }
  &__input {
    display: flex !important;
    border-radius: 10px;
    position: relative;
    align-items: center !important;
    border: 1px solid #c4c4c4;
    input {
      text-align: right !important;
      font-size: 1.5em !important;
      padding-right: 2.2em !important;
      height: 40px !important;
      width: 80% !important;
      font-size: 30px !important;
      padding: 0 0.5em;
      text-align: center;
      text-align: center;
      text-transform: initial;
      border: none !important;
      padding: 0 !important;
      font-family: "Roboto Medium";
      font-size: 24px !important;
      &:focus {
        border: none !important;
      }
    }
    span {
      font-size: 14px;
      margin-right: 10px;
      color: #777777;
      font-family: "Roboto Medium";
    }
  }
  &__badge {
    display: inline-block;
    position: absolute;
    bottom: -18px;
    right: 15px;
    background: none !important;
    color: black !important;
  }
  &__error {
    display: inline-block;
    position: absolute;
    padding: 0 !important;
    bottom: -20px;
    background: none !important;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Roboto" !important;
    font-size: 14px !important;
    color: #ff0000 !important;
  }
}
.border-input-error {
  border: 1px solid $color-danger !important;
}

.close-modal-button {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 22px;
  top: 18px;
  transform: rotate(45deg);
  color: #777777;
  cursor: pointer;
}

.modal-connect {
  padding: 3em 2em;
  .text-terms-conditions {
    font-size: 13px;
  }
}

.loading-waiting-wallet {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__loading {
    .content-loader {
      margin: 2.7em 0;
      transform: scale(2.6);
      height: 35px !important;
      width: 35px !important;
    }
    display: flex;
    justify-content: center;
  }
  &__message {
    margin-top: 1em;
    line-height: 1.8em;
    margin-bottom: 1.6em;
    font-size: 15px !important;
    text-align: center !important;
    b {
      color: $color-primary;
      span {
        margin-left: 0.3em;
      }
    }
  }
}

.transaction-complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__check {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 50px;
    height: 50px;
    font-size: 3em;
    border-radius: 50%;
    color: $color-primary;
    border: 1px solid $color-primary;
  }
  &__text {
    font-size: 17px !important;
  }
  &__button {
    margin-top: 3em;
  }
}

.modal-custom-padding {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 35px;
}
@media screen and (max-width: 620px) {
  .modalsize-create-label {
    width: 97%;
    min-width: 97% !important;
  }
}
.modal-for-add-token {
  width: 515px;
  max-width: 515px !important;

  .modal-content {
    .input-add-token {
      input {
        width: 415px;
      }
    }
  }
  @media screen and (max-width: 620px) {
    width: 97%;
    min-width: 97% !important;
    .modal-content {
      .input-add-token {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }
}
.modal-uniswap-widget {
  .uni-ic__modal__content {
    width: 90%;
  }
  .uni-ic__modal {
    padding: 0 !important;
    background: none !important;
  }
}
button:disabled {
  background: #b5b5b5 !important;
  cursor: not-allowed;
}
