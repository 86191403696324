


.btn-play {
  margin: auto;
  display: flex !important;
}
// ~
.btn-controls {
  display: none !important;
}
.video-controls:hover .btn-controls {
  display: flex !important;
}

.video-controls:hover .timecontrols {
  display: flex !important;
}
