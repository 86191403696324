.footer-content {
  padding-top: 3em;
  background: #f9f9f9;
  .container {
    display: flex;

    position: relative;
    align-items: center;
    flex-direction: column;
    p {
      margin-top: 2.5em;
      margin-bottom: 0.5em;
      text-align: center;
      font-size: 15px;
      @media screen and(max-width: 768px) {
        margin-top: 1.5em;
        margin-bottom: 2em;
      }
    }
  }
  img {
    width: 116px;
  }
  &__social {
    display: flex;
    position: absolute;
    left: 0;

    a {
      display: block;
      border-radius: 14px;
      width: 38px;
      height: 38px;
      color: $color-contrast;
      background: $color-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.7rem;
      &:first-child {
        margin-right: 1em;
      }
    }
    @media screen and(max-width: 768px) {
      left: 50%;
      transform: translateX(-50%);
      bottom: 13px;
      a {
        border-radius: 50%;
      }
    }
  }
}

.no-padding-footer {
  padding: 0.5em 0 !important;
  p {
    margin: 0 !important;
    padding: 0 !important;

    width: 100%;
    bottom: 0;
    padding: 1em;
    left: 0;
    right: 0;
    position: fixed;
    padding: 0.5em !important;
    background: #f9f9f9;
    z-index: 999;
  }
}

.backgorundcolor {
  background: #faf5ff;
}
