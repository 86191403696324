.nfts-collections {
  &__profile {
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: $tablet) {
      .u-padding-left-2 {
        margin-bottom: 10px;
      }
    }
  }
  &__left {
    width: 32%;
    .u-margin-bottom-2 {
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid $color-extra-grey-2;
    }
    @media screen and (max-width: $xl) {
      width: 39%;
      padding-left: 0;
    }

    @media screen and (max-width: $tablet) {
      width: 100%;
      padding-left: 0;
      @media screen and (max-width: $tablet) {
        .u-margin-bottom-2 {
          border: none;
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $xl) {
      flex-direction: row-reverse;
    }

    @media screen and (max-width: $lg) {
      // flex-direction: column;
      .u-padding-left-2 {
        padding-left: 0;
      }
    }
    @media screen and (max-width: $tablet) {
      .u-margin-bottom-2 {
        margin: 0 !important;
      }
    }
  }
  &__center {
    // width: 50%;
    padding-left: 40px;
    padding-right: 40px;
    @media screen and (max-width: $xl) {
      width: 61%;
      padding-left: 0;
    }

    @media screen and (max-width: $tablet) {
      padding: 0;
      width: 100%;
    }
  }
  &__right {
    width: 18%;
  }
  &__content {
    // width: 525px;
    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }
  .profile-address {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.hidde-last-time {
  p {
    display: none;
  }
}

.circle-skeleton {
  margin-right: 1em;
  border-radius: 50% !important;
  transform: scale(1) !important;
}
