.supply-bar {
  padding-bottom: 2em;

  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &__progress {
    width: 50%;
  }
  &__max-total {     
    display: block;
    margin: auto;
  }
  @media screen and (max-width: 560px) {
    flex-direction: column;
    &__progress {
      width: 100%;
    }
    &__max-total {
      width: 100%;
    }
  }
  &__calc-supply {
    margin: 0 0 1em;
    display: flex;
    span {
      font-size: 12px;
      margin-right: 0.3em;
      color: #666;
      font-family: "Roboto Medium";
    }
    &--tool-info {
      position: relative;
      margin: 0 0.2em;
      cursor: pointer;

      svg {
        color: $color-tertiary-light;
      }
    }
    &--tool-info:hover > .supply-bar__calc-supply--info-message {
      opacity: 1;
      transform: scale(1);
    }
    &--info-message {
      opacity: 0;
      transform: scale(0);
      transition: 0.2s ease all;
      transform-origin: 40% top;

      top: 20px;
      left: -100px;
      width: 300px;
      padding: 1em;
      font-size: 0.8rem;
      transform: scale(0);
      position: absolute;
      border-radius: 5px;
      font-family: "Roboto Medium";
      color: $color-extra-grey;
      background: $color-contrast;
      border: 1px solid $color-tertiary-light;
      &::before {
        top: -5px;
        left: 100px;
        position: absolute;
        width: 12px;
        height: 12px;
        box-shadow: -1px -1px 0px 0px $color-tertiary-light;
        // border: 1px solid $color-tertiary-light;
        background: $color-contrast;
        transform: rotate(45deg);
        content: "";
      }
    }

    &--tool-verify {
      position: relative;
      margin: 0 0.2em;
      cursor: pointer;
      svg {
        color: $color-primary;
      }
    }
    &--tool-verify:hover > .supply-bar__calc-supply--info-verify {
      opacity: 1;
      transform: scale(1);
      z-index: 2;
    }
    &--info-verify {
      opacity: 0;
      transform: scale(0);
      transition: 0.2s ease all;
      transform-origin: 40% top;
      left: -100px;
      top: 22px;
      width: 300px;
      position: absolute;
      border-radius: 5px;
      padding: 1em;
      font-size: 0.8rem;
      font-family: "Roboto Medium";
      color: $color-extra-grey;
      background: $color-contrast;
      border: 1px solid $color-tertiary-light;
      &::before {
        top: -5px;
        left: 100px;
        position: absolute;
        width: 12px;
        height: 12px;
        box-shadow: -1px -1px 0px 0px $color-tertiary-light;
        // border: 1px solid $color-tertiary-light;
        background: $color-contrast;
        transform: rotate(45deg);
        content: "";
      }
      .text-verify {
        font-family: "Roboto Medium";
        span {
          display: flex;
          margin-bottom: 1em;
          align-items: center;
          color: $color-tertiary;
          svg {
            margin-right: 0.5em;
          }
        }
      }
      .total-suply {
        margin-top: 1em;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 0.8rem;
          color: $color-tertiary;
        }
      }
    }
  }
  &__total-tokens {
    display: flex;
    justify-content: space-between;
    &--amount {
      font-size: 18px;
      font-family: "Roboto Bold";
    }
    &--percentage {
      font-size: 0.9rem;
      font-family: "Roboto";
      color: $color-primary;
      position: absolute;
      right: -25px;
      top: 15px;
    }
  }
  &__bar-progress {
    margin: 1em 0;
    margin-bottom: 2em;
    padding-left: 13px;
    padding-right: 13px;
    &--bar-100 {
      width: 100%;
      height: 1px;
      background: $color-extra-grey;
      position: relative;
      &::before {
        content: "";
        width: 12px;
        top: -6px;
        left: -13px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid $color-extra-grey;
        background: $color-contrast;
        position: absolute;
      }
      &::after {
        content: "";
        width: 12px;
        top: -6px;
        right: -13px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid $color-extra-grey;
        background: $color-contrast;
        position: absolute;
      }
    }
    &--progress {
      top: -1px;
      height: 3px;
      display: block;
      position: relative;
      background: $color-primary;
      &::before {
        content: "";
        width: 13px;
        height: 13px;
        position: absolute;
        border-radius: 50%;
        background: $color-primary;
        right: -13px;
        top: -5px;
      }
    }
  }
  &__max-supply {
    margin: 0.3em 0;
    display: flex;
    justify-content: space-between;
    &--tool-info {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      position: relative;
      color: #666666;
      font-family: "Roboto Medium";
      margin-right: 1.2em;
      svg {
        margin-top: 0.2em;
        margin-left: 0.5em;
        font-size: 0.9rem;
        font-family: "Roboto Medium";
        color: $color-tertiary-light;
      }
    }
    &--total {
      font-size: 12px;
      color: $color-dark;
      font-family: "Roboto Medium";
    }
    &--info-max-supply {
      display: flex;
      position: relative;
      cursor: pointer;
    }
    &--info-max-supply:hover > .supply-bar__max-supply--message-max-supply {
      opacity: 1;
      transform: scale(1);
      z-index: 9;
    }
    &--message-max-supply {
      opacity: 0;
      transform: scale(0);
      transition: 0.2s ease all;
      transform-origin: 40% top;
      left: -95px;
      top: 23px;
      width: 300px;
      position: absolute;
      border-radius: 5px;
      padding: 1em;
      font-size: 0.8rem;
      font-family: "Roboto Medium";
      color: $color-extra-grey;
      background: $color-contrast;
      border: 1px solid $color-tertiary-light;
      &::before {
        top: -5px;
        left: 100px;
        position: absolute;
        width: 12px;
        height: 12px;
        box-shadow: -1px -1px 0px 0px $color-tertiary-light;
        // border: 1px solid $color-tertiary-light;
        background: $color-contrast;
        transform: rotate(45deg);
        content: "";
      }
    }
  }

  &__total-supply {
    margin: 0.2em 0;
    display: flex;
    justify-content: space-between;
    &--tool-info {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      margin-right: 1.2em;
      color: #666666;
      font-family: "Roboto Medium";

      svg {
        margin-top: 0.2em;
        margin-left: 0.5em;
        font-size: 0.9rem;
        font-family: "Roboto Medium";
        color: $color-tertiary-light;
      }
    }

    &--info-total-supply {
      display: flex;
      position: relative;
      cursor: pointer;
    }
    &--info-total-supply:hover
      > .supply-bar__total-supply--message-total-supply {
      opacity: 1;
      z-index: 9;
      transform: scale(1);
    }
    &--message-total-supply {
      opacity: 0;
      transform: scale(0);
      transition: 0.2s ease all;
      transform-origin: 40% top;
      left: -95px;
      top: 23px;
      width: 300px;
      position: absolute;
      border-radius: 5px;
      padding: 1em;
      font-size: 0.8rem;
      font-family: "Roboto Medium";
      color: $color-extra-grey;
      background: $color-contrast;
      border: 1px solid $color-tertiary-light;
      &::before {
        top: -5px;
        left: 100px;
        position: absolute;
        width: 12px;
        height: 12px;
        box-shadow: -1px -1px 0px 0px $color-tertiary-light;
        // border: 1px solid $color-tertiary-light;
        background: $color-contrast;
        transform: rotate(45deg);
        content: "";
      }
    }

    &--total {
      font-size: 12px;
      color: $color-dark;
      font-family: "Roboto Medium";
    }
  }
}
