.page-not-found {
  padding: 5em 0;
  text-align: center;
  min-height: calc(100vh - 210px);
  @media screen and (max-width: $xl) {
    min-height: calc(100vh - 180px);
  }
  @media screen and (max-width: 1080px) {
    min-height: calc(100vh - 160px);
  }
  @media screen and (max-width: $lg) {
    min-height: calc(100vh - 90px);
    padding-top: 55px;
  }
  @media screen and (max-width: $lg) {
    max-width: 100%;
    // height: 100vh;
    // justify-content: space-between;
  }
  h4 {
    font-size: 7em;
    color: $color-dark;
    font-family: "Roboto Bold";
  }
  p {
    margin: 1em 0;
    color: $color-dark;
  }
  a {
    display: block;
    margin-top: 4em;
    color: $color-primary;
  }
}

.catch-error {
  padding: 1em;
  p {
    font-size: 1rem;
    color: $color-dark;
    margin-bottom: 0.3em;
    span {
      font-size: 1rem;
      color: $color-danger;
    }
    code {
      color: $color-dark;
      font-size: 1rem;
      background: rgb(241, 241, 241);
    }
  }
  &__error {
    margin-bottom: 2em!important;
  }
  a {
    text-decoration: underline;
    font-size: 1rem;
    color: $color-primary;
  }
}
