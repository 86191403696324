.checkbox-group {
  width: 350px;
  margin: auto;
  display: block;
  border-radius: 10px;
  text-align: center;
  text-transform: initial;
  border: 1px solid #c4c4c4;
  position: relative;
  padding: 0.7em 1em 0.5em 1em;
  margin-bottom: 1em;
  &__group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__title {
    display: block;
    color: #777777;
    background: $color-contrast;
    margin-bottom: 1em;
  }
  &__item {
    height: 40px;
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"]:checked + label::after {
      opacity: 1;
      transform: rotate(30deg) scale(1);
      transition: 0.1s all ease;
    }
    input[type="checkbox"]:checked + label::before {
      background: $color-primary;
      border: none;
    }
    label {
      font-size: 14px;
      cursor: pointer;
      position: relative;
      padding-left: 28px;
      margin-right: 0.8em;
      display: block;
      transition: 0.3s all ease;
      color: #777777;
      &::before {
        top: 0px;
        content: "";
        left: 3px;

        width: 15px;
        height: 15px;
        border-radius: 3px;
        position: absolute;
        background: $color-contrast;
        border: 1px solid $color-tertiary-light;
      }
      &::after {
        top: 2px;
        opacity: 1;
        width: 3px;
        content: "";
        left: 8px;
        height: 8px;
        position: absolute;
        transform: rotate(80deg) scale(0);
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
    }
  }
}
