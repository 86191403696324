// Color primary
$color-primary: #8f4ae7;
$color-primary-dark: #7626dc;
$color-primary-light: #a670eb;
$color-primary-extra-light: #f7f0ff;
$color-primary-f2e8ff: #f2e8ff;

$color-primary-light-d0acff: #d0acff;

// Color secondary
$color-secondary: #4ac9db;
$color-secondary-dark: #04a2b8;
$color-secondary-light: #a6eaf4;
$color-secondary-extra-light: #cbdfe2;
// Color tertiary
$color-tertiary: #3f3f3f;
$color-tertiary-dark: #100b0b;
$color-tertiary-light: #bbb6b6;
$color-tertiary-extra-light: #f7fcff;
// Color danger
$color-danger: #f32b2b;
$color-dark-light: #fdadad;
// Color dark
$color-dark: #3f3f3f;
$color-dark-light: #929191;
$color-dark-extra-light: #f0f0f0;
$color-dark-dcdc: #dcdcdc;
$color-contrast: #fff;

$color-extra: #63ae28;
$color-extraDark: #315f0e;
$color-extraLight: #97d667;
$color-extraExtraLight: #f2ffe8;
$color-extraNoFill: #efffe2;

$color-extra-grey: #999999;
$color-dark-dark777: #777777;
$color-extra-grey-2: #c5c5c5;

$color-light-grey: #dadada;

$color-extra-light-grey: #f5f5f5;

$color-widget-title: #747474;

$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$tablet: 870px;
$smartphone: 580px;
