.question-table {
  border-collapse: collapse;
  border: 1px solid rgba(224, 224, 224, 1);
  tr {
    min-height: 52px;
    height: 52px;
    border: 1px solid rgba(224, 224, 224, 1);
    th {
      text-align: left;
      padding: 0 10px;
      overflow: hidden;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      div {
        padding-right: 20px;
        border-right: 2px solid rgba(224, 224, 224, 1);
      }
      &:last-child {
        div {
          border-right: none;
        }
      }
    }
    td {
      padding: 0 10px;
    }
  }
}


