.show-drop-down-menu {
  opacity: 1 !important;
  transform: scaleY(1) !important;
}

.search-dropdown-container {
  width: 470px;
  margin: auto;
  text-align: center;
  @media screen and (max-width: $tablet) {
    width: 100%;
    input {
      width: 100% !important;
      padding-right: 55px !important;
    }
    input::placeholder {
      color: rgb(209, 209, 209);
    }
  }
  .logo-search {
    width: 173px;
    margin-bottom: 30px;
  }
}

// .dropdown-out-home {
//   img {
//     width: 120px;
//   }
// }

.height-search-box.sticky-search-bar {
  @media screen and (max-width: $tablet) {
    width: 94% !important;
    .nocliking {
      display: none !important;
    }
  }

  @media screen and(max-width: $tablet) {
    .card-suggestion-space {
      padding: 15px;
      padding-top: 50px;
      ul {
        li {
          a {
            margin: 0;
          }
        }
      }
    }
  }
}
.search-dropdown-container.height-search-box {
  @media screen and (max-width: $tablet) {
    padding-right: 20px !important;
  }
}
