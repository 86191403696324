.title-section {
  font-size: 1.3rem;
}
.title-secondary {
  color: $color-secondary;
}
.text-danger {
  color: $color-danger;
}
.text-primary {
  color: $color-primary;
}
.u-color-primary {
  color: $color-primary !important;
}
.u-color-7474 {
  color: $color-widget-title !important;
}
.text-secondary {
  color: $color-secondary;
}
.u-no-margin {
  margin: 0 !important;
}
.u-display-none {
  display: none !important;
}
.u-justify-spacebetween {
  justify-content: space-between;
}
.u-margin-left-05 {
  margin-left: 0.5em;
}
.u-display-inline-block {
  display: inline-block;
}
.text-small {
  font-size: 0.8rem;
}
.u-position-relative {
  position: relative;
}
.u-position-absolute {
  position: absolute;
}
.u-margin-left-none {
  margin-left: 0 !important;
}
.u-right-0 {
  right: 0;
}
.u-padding-left-1 {
  padding-left: 1em;
}
.u-padding-left-3 {
  padding-left: 3em;
}
.u-padding-bottom-05 {
  padding-bottom: 0.5em;
}
.u-padding-top-none {
  padding-top: 0;
}
.u-cursor-pointer {
  cursor: pointer;
}
.u-padding-all-1 {
  padding: 1em;
}
.u-padding-0 {
  padding: 0 !important;
}
.u-padding-tb-05 {
  padding: 0.5em !important;
}
.u-padding-top-05 {
  padding-top: 0.5em;
}
.u-padding-top-bottom-1 {
  padding: 1em 0;
}
.u-text-center {
  text-align: center;
}
.u-margin-auto {
  margin: auto;
}
.u-margin-top-05 {
  margin-top: 0.5em;
}
.u-margin-top-1 {
  margin-top: 1em;
}
.u-margin-top-1-5 {
  margin-top: 1.5em;
}
.u-margin-tb-2 {
  margin: 2em 0;
}
.u-margin-bottom-5 {
  margin-bottom: 5em;
}
.u-margin-top-5 {
  margin-top: 5em;
}
.u-margin-top-8 {
  margin-top: 8em;
}
.u-margin-left-3 {
  margin-left: 3em;
}
.u-margin-left-2-7 {
  margin-left: 2.7em;
}
.u-margin-bottom-05 {
  margin-bottom: 0.5em;
}
.u-margin-bottom-1 {
  margin-bottom: 1em !important;
}
.u-margin-bottom-2 {
  margin-bottom: 2.5em !important;
}
.u-mb-2 {
  margin-bottom: 2em;
}
.u-margin-top-2 {
  margin-top: 2em !important;
}
.u-padding-top-1 {
  padding-top: 1em;
}
.u-padding-top-5 {
  padding-top: 5em;
}
.u-width-400 {
  width: 400px !important;
}
.u-padding-top-3 {
  padding-top: 3em;
}
.u-padding-lr-2 {
  padding: 0 2em;
}
.u-padding-mobil-top-5 {
  @media screen and (max-width: $lg) {
    padding-top: 5em !important;
  }
}
.u-padding-mobil-top-3 {
  @media screen and (max-width: $lg) {
    padding-top: 4em !important;
  }
}
.u-rotate-180 {
  transform: rotate(180deg);
}

.u-cursor-notallowed {
  cursor: not-allowed;
}

.u-text-align-vh {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.u-padding-top-2 {
  padding-top: 2em;
}
.u-width-100 {
  width: 100% !important;
}
.u-padding-none {
  padding: 0 0 !important;
}
.u-padding-bottom-1 {
  padding-bottom: 0.5em !important;
}
.u-padding-bottom-2 {
  padding-bottom: 2em;
}
.u-width-50 {
  width: 50%;
}
.u-margin-right-1 {
  margin-right: 1em;
}
.u-overflow-scroll-x {
  overflow-x: auto;
}
.u-margin-tb-1 {
  margin: 1em 0;
}
.u-margin-left-1 {
  margin-left: 1em;
}
.u-width-20 {
  width: 20px !important;
}
.u-margin-tb-3 {
  margin: 3em 0;
}
.u-margin-tb-4 {
  margin: 4em 0;
}
.u-margin-top-3 {
  margin-top: 3em;
}
.u-margin-top-4 {
  margin-top: 4em;
}
.u-margin-tb-2 {
  margin: 2em 0;
}
.u-display-flex {
  display: flex;
}

.u-no-bold {
  font-family: "Roboto" !important;
}
.u-justify-center {
  justify-content: center;
}
.u-justify-space-around {
  justify-content: space-around;
}
.u-break-word {
  word-break: break-word;
}
.u-align-items-bottom {
  align-items: flex-end;
}
.u-align-items-center {
  align-items: center;
}
.u-margin-bottom-1-2 {
  margin-bottom: 1.2em;
}
.u-align-items-baseline {
  align-items: baseline;
}
.u-padding-left-2 {
  padding-left: 2em;
}
.u-padding-top-2-5 {
  padding-top: 2.5em !important;
}
.u-margin-bottom-3 {
  margin-bottom: 3em;
}
.u-margin-bottom-1 {
  margin-bottom: 1em;
}
.u-margin-right-05 {
  margin-left: 0.5em;
}
.u-margin-bottom-4 {
  margin-bottom: 4em;
}
.u-border-bottom-1 {
  border-bottom: 1px solid #c5c5c5;
}
.u-border-bottom-1 {
  border-bottom: 1px solid #c5c5c5;
}
.u-border-bottom-1-dcdc {
  border-bottom: 1px solid #dcdcdc;
}
.u-border-bottom-1-danger {
  border-bottom: 1px solid #f32b2b !important;
  border-radius: 0 !important;
  padding: 0 !important;
  display: block !important;
  margin: auto !important;
}
.u-display-inline-block {
  display: inline-block;
}
.u-color-dark-secondary {
  color: $color-secondary;
}
.u-color-99999 {
  color: $color-extra-grey;
}
.u-center-element {
  display: flex;
  justify-content: center;
  align-items: center;
}
.u-height-auto {
  height: auto !important;
}
.u-word-break-all {
  word-break: break-all;
}
.u-border-top-0 {
  border-top: 0 !important;
}
.u-border-right {
  border-right: 1px solid $color-secondary-extra-light;
}
.u-margin-right-ui-1 {
  margin-right: 1em !important;
}
.u-padding-lr-ui-1 {
  padding: 0 1em !important;
}
.u-padding-lr-1 {
  padding-left: 1em;
  padding-right: 1em;
}
.u-text-underline {
  text-decoration: underline;
}
.u-color-tertiary {
  color: #999999 !important;
}
.u-color-tertiary-5 {
  color: $color-tertiary !important;
}
.u-padding-lr-2 {
  padding-left: 2em;
  padding-right: 2em;
}
.u-pointer-events-none {
  pointer-events: none;
}
.u-margin-bottom-1-5 {
  margin-bottom: 1.5em !important;
}
.u-margin-bottom-1-7 {
  margin-bottom: 1.7em !important;
}
.u-overflow-x-scroll {
  overflow-x: auto;
}
.u-padding-lr-25 {
  padding: 0 2.5em !important;
}
.u-margin-amount {
  margin-left: 2.5em;
}
.u-margin-left-2 {
  margin-left: 2em;
}
.u-size-text-18 {
  font-size: 18px !important;
}
.u-rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s ease all;
}
.u-display-inline-block {
  display: inline-block !important;
}
.u-margin-left-03 {
  margin-left: 0.3em;
}
.u-margin-right-03 {
  margin-right: 0.3em;
}
.u-input-border-red {
  input {
    border-color: $color-danger !important;
  }
}
.input-address {
  input:disabled {
    cursor: not-allowed;
    color: #9c9c9c !important;
    background: rgb(235, 235, 235);
  }
}
.u-align-item-start {
  align-items: flex-start !important;
}
.u-margin-top-none {
  margin-top: 0;
}
.u-space-between {
  justify-content: space-between;
}
.u-text-justify {
  line-height: 22px;
  text-align: justify;
}
.u-scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
}
.u-max-scroll-400 {
  max-height: 400px;
}
.u-max-scroll-600 {
  max-height: 600px;
}
.u-zindex-99 {
  z-index: 99;
}
.u-font-capitalize {
  text-transform: capitalize;
}
.u-margin-none {
  margin-top: 0 !important;
}
.text-card-padding {
  font-size: 15px !important;
  padding-top: 1.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.u-tiny-text {
  font-size: 11px !important;
  @media screen and (max-width: 1403px) {
    font-size: 10px !important;
  }
  @media screen and (max-width: $lg) {
    font-size: 9px !important;
  }
  @media screen and (max-width: $tablet) {
    font-size: 12px !important;
  }
}
.u-padding-left-5 {
  padding-left: 5em;
}
.u-tiny-text-2 {
  @media screen and (max-width: $lg) {
    font-size: 11px !important;
  }
  @media screen and (max-width: 895px) {
    font-size: 10px !important;
  }
  @media screen and (max-width: $tablet) {
    font-size: 12px !important;
  }
}
.u-text-transform-lowercase {
  text-transform: lowercase;
}
.u-flex-direction-column {
  flex-direction: column;
}
.u-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
}
.u-flex-direction-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.u-zindex-9 {
  z-index: 9;
}
.u-padding-lr-06px {
  padding: 0;
  width: calc(100% / 3 - 8px);
}

.position-contract {
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.u-width-fc-200 {
  min-width: 120px !important;
}
.u-width-fc-500 {
  min-width: 500px !important;
}
.u-flex-order-down {
  order: 9;
}
.edit-contract {
  font-size: 0.9rem !important;
  width: auto !important;
  margin-left: 0.5em !important;
}
.show-dashboard {
  animation: show-dashboard 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @keyframes show-dashboard {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
.u-overflow-x-hidden {
  overflow-x: hidden;
}
.u-only-smartphone {
  display: none !important;
  @media screen and (max-width: $tablet) {
    display: block !important;
  }
}

.u-color-dark {
  color: $color-dark !important;
}
.select-list-order {
  width: 180px;
}
.select-icon-content {
  .select-list-order {
    .select-option__control {
      height: 28px !important;
      min-height: 28px !important;
      box-shadow: none;
      border-color: hsl(0, 0%, 80%) !important;
      &:hover {
        border-color: hsl(0, 0%, 80%) !important;
      }
      padding-right: 25px;
      .select-option__value-container {
        text-align: left !important;
        color: $color-tertiary;
      }
    }
    .select-option__option {
      color: $color-tertiary;
      font-size: 0.9rem !important;
    }
    .select-option__menu {
      text-align: left !important;
    }
  }
  svg {
    top: 0;
    right: 0;
    width: 25px;
    height: 28px;
    position: absolute;
    color: $color-tertiary;
  }
}
.u-only-desktop {
  display: flex !important;
  @media screen and (max-width: $tablet) {
    display: none !important;
  }
}
.u-border-none {
  border: none !important;
}
.u-border-color-secondary {
  border: 1px solid $color-secondary;
}
.u-squere-left {
  font-family: "Roboto Medium" !important;
  position: relative;
  margin-left: 25px;
  font-size: 16px !important;
  color: #222222 !important;
  &::before {
    content: "";
    left: -20px;
    top: 3px;
    position: absolute;
    height: 10px;
    width: 10px;
    background: $color-primary;
  }
}
.u-color-secondary {
  color: $color-secondary !important;
}
.u-line-height-2 {
  line-height: 2em;
}

.u-font-weight-bold {
  font-family: "Roboto Medium" !important;
}
.u-justify-around {
  justify-content: space-around;
}
.u-text-size-small {
  font-size: 13px !important;
}
.u-padding-right-1-5 {
  padding-right: 1.5em;
  @media screen and (max-width: $lg) {
    padding-right: 0.7em;
  }
}

.u-no-padding {
  padding: 0;
}
.group-buttons-company {
  align-items: center;
  flex-direction: column;
  // @media screen and (max-width: $lg) {
  //   flex-direction: column;
  //   button {
  //     margin: 0.5em 0 !important;
  //   }
  //   a{
  //     margin-top: 1em;
  //   }
  // }
  button {
    // margin-bottom: 1em;
  }
}
.group-buttons-address {
  display: flex;
  align-items: center;
  justify-content: center;
}
.head-modal-addNew {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.btn-remove-token {
  cursor: pointer;
  color: $color-danger;
  background: none;
  font-family: "Roboto Medium";
  border: none;
  display: flex;
  align-items: center;
  font-size: 1.3em;
}
.label-loader-icon {
  display: flex;
  justify-content: center;
  width: 160px;
}
.form-loader {
  .content-loader img {
    width: 28px !important;
  }
}
.tooltip-copied {
  position: absolute;
  color: $color-contrast;
  font-size: 0.8rem;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  background: $color-secondary;
  top: -25px;
  left: 50%;
  transform: translateX(-50%) translateY(15px);
  opacity: 0;
  animation: 0.2s copy-text 1 forwards;
  @keyframes copy-text {
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
}

.button-only-test {
  position: absolute !important;
  right: 0;
  top: 0;
  font-size: 0.8rem !important;
  background: none !important;
  color: $color-tertiary !important;
}

.list-actions {
  margin-top: 5px;
  border: 0;
  z-index: 9;
  position: absolute;
  border-radius: 20px;
  left: 40px;
  top: -8px;
  width: 225px;
  background: #f1f1f1;
  transform: scaleY(0);
  transform-origin: 10% 20%;
  padding: 15px;
  @media screen and (max-width: 768px) {
    width: 175px;
  }
  @media screen and (max-width: 360px) {
    left: -65px;
    top: 21px;
  }
  //transition: 0.15s cubic-bezier(0.9, 0.03, 0, 1.02) all;
  button {
    font-family: "Roboto" !important;
    font-size: 13px !important;
    background: none !important;
    color: #777 !important;
    justify-content: flex-start;
    height: 23px;
    width: auto;
    padding: 0 0.5em;
  }
}

.select-list {
  opacity: 0;
  animation: 0.2s show-drop-items 1 forwards;
  @keyframes show-drop-items {
    to {
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
.select-list-tokens {
  opacity: 1 !important;
  transform: scaleY(1) !important;
}
.select-list-settings {
  opacity: 1 !important;
  transform: scaleY(1) !important;
}

.u-width-165 {
  width: 165px;
}

.title-circle {
  position: relative;
  padding-left: 10px;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  color: $color-primary !important;
  &::before {
    left: 0;
    top: 6px;
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    background: $color-primary-light;
  }
}

.element_box-search {
  input {
    padding: 0 3em !important;
  }
}

.u-height-100 {
  height: 100%;
}
.u-color-dark {
  color: $color-dark;
}
.u-margin-left-5 {
  margin-left: 5em;
}
.u-weight-medium {
  font-family: "Roboto Medium";
}
.u-font-15 {
  @media screen and (max-width: 520px) {
    font-size: 15px !important;
  }
}
.u-height-100vh {
  height: 100vh;
}
.line-bottom {
  left: 0;
  top: auto;
  width: 100%;
  border: none;
  bottom: auto;
  position: absolute;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.no-results-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
  svg {
    font-size: 3em;
    color: #c7c7c7;
  }
  span {
    color: #c7c7c7;
    margin-top: 1em;
  }
}
.u-text-no-bold {
  font-family: "Roboto" !important;
}
.loader-search-investment {
  margin-right: 1em;
  padding: 0 1em;
}

.color-extra-danger {
  color: #ff0000 !important;
  font-family: "Roboto" !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  text-align: center !important;
  padding-right: 0 !important;
  margin: auto !important;
  display: block;
  width: auto !important;
  border-bottom: 1px solid #ff0000 !important;
}

.center-no-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-all-tokens {
  max-height: 300px;
  width: 348px;
  overflow-y: scroll;
  overflow-x: hidden;
  // border: 1px solid #c5c5c5;

  border-radius: 10px;
  .element_row-list {
    padding: 1em;
  }
}
.icon-project-name {
  display: flex;
  align-items: center;
  .project-name__token {
    // width: 155px;
    padding-bottom: 0.2em;
    font-size: 14px !important;
  }
  .project-name__symbol {
    font-size: 12px !important;
  }
}

.u-border-boxsearch {
  overflow: hidden;

  border-radius: 10px;
  border: 1px solid #c5c5c5;
  position: relative;
  top: -7px;
  z-index: 0;
  padding-top: 0.5em;
  top: -12px;
}

.clean-text {
  position: absolute;
  right: 8px;
  top: 12px;
  cursor: pointer;
}

.wrap-content-page {
  padding-top: 3em;
  @media screen and (max-width: $lg) {
    padding-top: 1em;
    padding-left: 1.4em;
    padding-right: 1.4em;
  }
}
.u-text-uppercase {
  text-transform: uppercase;
}
.u-color-green {
  color: $color-extra !important;
}
.u-font-regular {
  font-family: "Roboto" !important;
}

@media screen and (max-width: 768px) {
  .u-max-width-188px {
    max-width: 188px;
    min-width: 188px;
  }
  .u-align-right {
    align-items: flex-end;
  }
  .u-text-left {
    text-align: left !important;
  }
  .u-no-show-mobil {
    display: none;
  }
}
@media screen and (max-width: 345px) {
  .u-max-width-188px {
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .u-width-155 {
    max-width: 200px;
  }
}

@media screen and (max-width: 360px) {
  .u-width-155 {
    max-width: 155px;
  }
}
.u-color-extra-grey {
  color: $color-extra-grey;
}
.u-color-danger {
  color: $color-danger !important;
}
.only-home-logo {
  display: none;
}
@media screen and (max-width: $lg) {
  .only-home-logo {
    display: block;

    display: block;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 18px;
    z-index: 9999;
    img {
      width: 120px;
    }
  }
}

@keyframes placeHolderShimmer {
  0% {
    transform: translateZ(0);
    background-position: -468px 0;
  }
  to {
    transform: translateZ(0);
    background-position: 468px 0;
  }
}

.skeleton {
  position: relative;
}

.animated-background {
  will-change: transform;
  background: #e6e6e6;
  position: relative;
  animation: placeHolderShimmer 1s linear infinite forwards;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  height: 100%;
}

.skel-item {
  top: 0;
  left: 0;
}

.add-fade-to-top {
  animation: fade-to-top 0.8s cubic-bezier(0.2, 0.31, 0.18, 0.99) both;
}
@keyframes fade-to-top {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.add-fade-to-bottom {
  animation: fade-to-bottom 0.5s cubic-bezier(0.2, 0.31, 0.18, 0.99) both;
}
@keyframes fade-to-bottom {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}

.u-ellipsis {
  text-overflow: ellipsis;
}
.u-text-lowercase {
  text-transform: lowercase;
}
.auxiliary-links {
  right: 0;
  top: 45px;
  position: absolute;
  a {
    margin-right: 1em;
    display: inline-block;
  }
}

.bubble-content {
  p {
    z-index: 1;
    font-size: 12px;
    color: $color-dark;
    position: relative;
    @media screen and (max-width: $lg) {
      top: 0;
      margin-bottom: 3em;
    }
  }
  > div {
    position: relative;
    left: -15px;
  }
}
.graph-label {
  z-index: 99999;
  font-size: 12px;
  margin-top: 2.5em;
  color: $color-dark;
}
.text-placeholder {
  font-size: 13px;
  font-family: "Roboto";
  color: $color-extra-grey;
}
.u-sticky-content {
  top: 170px;
  position: sticky;
  // overflow-y: scroll;
  // overflow-x: hidden;
  max-height: calc(100vh - 280px);
  @media screen and (max-width: $xl) {
    position: initial;
    max-height: 100%;
  }
}

.activity-tag {
  display: flex;
  font-size: 15px;
  margin-left: 10px;
  margin-bottom: 4px;
  color: $color-dark;
  align-items: flex-end;
}

.u-required-field {
  color: $color-danger;
  position: relative;
  top: -12px;
  font-size: 14px;
  font-family: "Roboto";
}

.small_size {
  width: 73px !important;
  height: 73px !important;
}
.big_size {
  width: 316px !important;
  height: 316px !important;
}
.medium_size {
  width: 100px !important;
  height: 100px !important;
}

.width-200 {
  width: 204px !important;
  height: 204px !important;
}
.width-150 {
  width: 150px !important;
  height: 150px !important;
}

.aux-links {
  display: none !important;
  right: -3px;
  top: 2px;
  position: absolute;
  a {
    margin: 0 5px;
    font-size: 14px !important;
    color: $color-primary !important;
  }
}

.u-hover-box:hover .aux-links {
  display: flex !important;
}

.nft-owner-timeline {
  .date-nft-owner {
    top: 0;
    left: calc(448px - -30px);
  }
  .nft-group-transfers {
    a,
    .img-nft-assets {
      width: 73px !important;
      height: 73px !important;
    }
  }
  .tag-button {
    height: 16px;
    padding: 0 6px;
    line-height: 16px;
    color: $color-primary !important;
    background-color: $color-primary-f2e8ff !important;
  }
  .u-hover-box {
    margin-bottom: 2em !important;
  }
  .profile-owner__links {
    a {
      height: 16px;
      border: 0 !important;
      font-size: 12px !important;
      background: $color-primary-f2e8ff !important;
      div {
        height: 15px !important;
        vertical-align: text-top;
      }
    }
  }
  .nft-images {
    padding-bottom: 1em;
    border-bottom: 1px solid #c5c5c5;
    .profile-nfts {
      a {
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
          img {
            margin-right: 0;
          }
        }
        img {
          width: 85px;
          height: 85px;
        }
      }
    }
  }
  .profile-address__by {
    color: #999 !important;
  }
  .time-ago-nft-owner {
    left: calc(430px - 75px);
  }
  .current-profile-address {
    font-size: 15px !important;
    margin-left: 0px !important;
  }
  .profile-avatar {
    width: 30px !important;
    height: 30px !important;
    border: 1px solid;
    background: $color-primary-light-d0acff !important;
  }
}

.no-followers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    margin: 0.5em 0;
    font-size: 14px !important;
  }
  svg {
    opacity: 0.2;
    font-size: 3em;
    margin-bottom: 0.3em;
  }
}

.u-bg-contrast {
  background: $color-contrast;
}

.u-margin-bottom-medium {
  margin-bottom: 1.8em !important;
}
