body {
  font-family: "Roboto";
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  // font-size: 16px;
  scrollbar-width: thin;
  scrollbar-color: $color-primary-extra-light;
  box-sizing: border-box;
}
img {
  // image-rendering: -webkit-optimize-contrast !important;
  transform: translateZ(0);
  filter: blur(0);
  -webkit-filter: blur(0);
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

ul {
  list-style: none;
}
a {
  text-decoration: none;
}

div,
button,
p,
h1 {
  outline: none !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
}
.menu-custom-option {
  max-height: 190px !important;
}
.menu-custom-option.select-option__menu {
  ::-webkit-scrollbar-track {
    background-color: none;
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: none;
  }
  ::-webkit-scrollbar-thumb {
    border: 3px solid white;
  }
  outline: none !important;
}
.menu-custom-option.select-option__menu {
  border: none;
  outline: none !important;
}
.content-select-icon {
  outline: none !important;
  &__select {
    outline: none !important;
    border: 1px solid #c5c5c5;
    border-radius: 10px;
  }
}
.menu-custom-option.select-option__control {
  z-index: 1;
  border: none;
  outline: none !important;
  padding-right: 1em;
  padding-left: 1em;
}
.menu-custom-option.select-option__option {
  word-break: break-all;
}
.menu-custom-option.select-option__menu-list {
  padding-bottom: 0;
  outline: none !important;
}
.menu-custom-option.select-option__control.menu-custom-option.select-option__control--menu-is-open {
  border-bottom: 1px solid;
  outline: none !important;
}
.menu-custom-option.select-option__placeholder {
  color: #777777;
  font-family: "Roboto";
}
input::placeholder {
  color: #777777;
  opacity: 1;
}

input:-webkit-input-placeholder {
  color: #777777;
  opacity: 1;
}
input:-moz-placeholder {
  color: #777777;
  opacity: 1;
}
input:-moz-placeholder {
  color: #777777;
  opacity: 1;
}
input:-ms-input-placeholder {
  color: #777777;
  opacity: 1;
}

.menu-custom-option.select-option__input-container {
  color: #777777 !important;
}

.select-option {
  width: 100%;
  position: relative;
  &__single-value {
    color: #777777 !important;
  }
  &__control {
    cursor: pointer !important;
    height: 41px !important;
    border-radius: 10px !important;
    border-color: #c4c4c4 !important;
    box-shadow: none !important;
    // border-radius: 20px 20px 0 0 !important;
    &:hover {
      border-color: #c4c4c4 !important;
      box-shadow: none !important;
    }
  }
  &__value-container {
    text-align: center;
  }
  &__menu {
    color: #777777 !important;
    overflow: hidden;
    text-align: center;
    margin-top: 0 !important;
    border-radius: 10px !important;
    position: relative !important;
    // border-radius: 0 0 20px 20px !important;
    border: 1px solid #c5c5c5;
    border-top: 0;
    box-shadow: none !important;
  }
  &__option {
    cursor: pointer !important;
    color: #777777 !important;
    &--is-focused {
      background: none !important;
      font-family: "Roboto Medium" !important;
      color: $color-primary-light !important;
    }
  }
  &__option {
    color: #777777 !important;
    &--is-selected {
      font-family: "Roboto Medium" !important;
      background: none !important;
      color: $color-primary-light !important;
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__dropdown-indicator {
    display: none !important;
  }
  &__indicators {
    right: 0;
    position: absolute;
  }
  &__icon-select {
    right: 10px;
    top: 12px;
    position: absolute;
    color: $color-dark;
  }
}

.content-select-icon {
  position: relative;
  svg {
    position: absolute;
    top: 8px;
    right: 5px;
    color: #777777;
    font-size: 1.5em;
    pointer-events: none;
    z-index: 2;
  }
}

.Toastify__progress-bar--success {
  background: #63ae28 !important;
}
.Toastify__toast--success .Toastify__toast-icon {
  svg {
    fill: #63ae28 !important;
  }
}

.Toastify__progress-bar--error {
  background: $color-danger !important;
}
.Toastify__toast--error .Toastify__toast-icon {
  svg {
    fill: $color-danger !important;
  }
}

.Toastify__progress-bar--warning {
  background: #ffe65b !important;
}
.Toastify__toast--warning .Toastify__toast-icon {
  svg {
    fill: #ffe65b !important;
  }
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      width: 65px;
      text-align: center;
      font-size: 11px;
      border: none;
      background: none;
      color: #999999;
      font-size: 12px;
      font-family: "Roboto";
      pointer-events: none;
    }
  }
}

.btn-edit-calendar {
  cursor: pointer;
  font-size: 15px;
  background: none;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar {
  .react-datepicker__header {
    background-color: $color-primary;
  }
  .react-datepicker__triangle {
    &::before {
      border-bottom-color: #f0f0f0;
    }
    &::after {
      border-bottom-color: $color-primary !important;
    }
  }
  .react-datepicker__current-month {
    color: white;
    font-weight: 100;
  }
  .react-datepicker__day-name {
    color: white;
  }

  .react-datepicker__day.react-datepicker__day--selected {
    background-color: $color-primary;
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
    background-color: $color-primary;
  }
}

.drawer-material-ui {
  width: 240px;
  .MuiDrawer-paper {
    width: 240px;
  }
}

.admin-content {
  position: absolute;
  left: 0;
  overflow-x: scroll;
  width: 100vw;
  width: 100%;
  padding-left: 250px;
  .MuiDrawer-root.MuiDrawer-docked.drawer-material-ui {
    width: 0;
  }
}
.admin-header {
  max-width: 100% !important;
  padding-left: 275px !important;
  .container {
    width: 100%;
    max-width: 100%;
  }
}
.filter-admin-content {
  border: 1px solid #e0e0e0;
  padding: 0.5em 1em;
  width: fit-content;
  background: #fcfcfc;
  border-radius: 5px;
  span {
    margin-bottom: 0.5em;
    font-size: 14px;
    display: flex;
    align-content: center;
    color: $color-primary;
    font-family: "Roboto Medium";
    svg {
      margin-right: 0.5em;
      position: relative;
      top: 1px;
    }
  }
}

.widget-container {
  width: auto !important;
}

@media screen and (min-width: $tablet) {
  .show-smartphone {
    height: auto !important;
    overflow: visible !important;
    visibility: visible !important;
  }
}

@media (min-width: 600px) {
  .MuiContainer-fixed {
    max-width: 1280px !important;
  }
}

.gridjs-wrapper {
  font-size: 14px !important;
}
