.uniswap-container {
  .uni-ic.uni-ic__theme-background {
    padding: 0 2em;
  }
  .uni-ic__header-container {
    .uni-ic__header-content {
      font-size: 22px;
      margin-top: 1.5em;
      color: $color-primary;
      justify-content: center;
      .uni-ic__header-content-title {
        font-family: "Roboto Medium";
      }
      .uni-ic__header-content-settings {
        top: 20px;
        right: 10px;
        position: absolute;
      }
    }
  }
  .uni-ic__header-content-settings-button {
    font-size: 1rem;
    color: #c5c5c5;
  }
  .uni-ic__swap-input-container {
    margin: 1em 0 !important;
    .uni-ic__swap-input-content-main-from.uni-ic__theme-panel {
      height: 40px;
      padding: 0.2em;
      padding-right: 0.5em;
      margin-left: 1em;
      border-radius: 10px;
      border: 1px solid #c5c5c5;
      color: #222;
      background: $color-contrast;
    }
  }
  .uni-ic__swap-input-content-main-from-currency-container.uni-ic__theme-panel {
    height: 40px;
    padding: 0.2em;
    border-radius: 10px;
    background: $color-contrast;
    border: 1px solid #c5c5c5;
    color: #999999 !important;
  }
  .uni-ic__swap-content-balance-and-price__balance-text {
    span {
      color: #999999 !important;
    }
  }
  .uni-ic__swap-divider.uni-ic__theme-panel {
    margin-top: 0px;
    margin-bottom: 1px;
    height: 40px;
    border: none;
    background: none;
    svg {
      width: 23px;
      height: 23px;
      stroke: $color-primary;
    }
  }
  .uni-ic__swap-output-content-main-select.uni-ic__theme-panel {
    height: 40px;
    padding: 0.2em;
    border-radius: 10px;
    background: $color-contrast;
    border: 1px solid #c5c5c5;
    color: #999999 !important;
  }
  .uni-ic__swap-output-content-main-from.uni-ic__theme-panel {
    color: #222;
    height: 40px;
    padding: 0.2em;
    padding-right: 0.5em;
    margin-left: 1em;
    border-radius: 10px;
    border: 1px solid #c5c5c5;
    background: $color-contrast;
  }
  .uni-ic__swap-content-balance-and-price__price {
    color: #999999;
  }
  .uni-ic__swap-content-balance-and-price__price-text {
    color: #999999;
  }
  .uni-ic__swap-button.uni-ic__theme-background-button {
    border-radius: 40px;
    height: 40px;
    .uni-ic__swap-button-text {
      font-size: 16px;
    }
  }
  .uni-ic__swap-container {
    padding-bottom: 3em;
  }
  .uni-ic__swap-button.uni-ic__theme-background-button:disabled {
    color: white;
    margin-top: 2em;
    background: #b5b5b5 !important;
  }
}

.uni-ic__modal__content.uni-ic__modal-tokens.uni-ic__theme-background {
  top: 40%;
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid $color-primary;
  transform: translateY(-50%);
  .uni-ic__modal-tokens-title {
    color: $color-dark;
    margin-bottom: 1em;
    font-size: 1rem;
  }
  .uni-ic__modal__close {
    position: absolute;
    top: 15px;
    right: 20px;
    color: tomato;
    font-size: 1.3em;
    height: 20px;
    color: $color-dark;
  }
  .uni-ic__modal-tokens-search__input {
    height: 40px;
    font-size: 1rem;
    color: #999999;
    margin-bottom: 0;
    border-radius: 10px;
  }
}

.uni-ic__modal-tokens-search + div{
  background: tomato;
  padding: 10px;
  top: -20px;
  z-index: 0;
}