.nft-content {
  width: 100%;
  display: flex;
  margin-left: 0;
  flex-wrap: wrap;
  .update-ago {
    margin-top: 0;
  }
  @media screen and (max-width: $tablet) {
    padding: 0 10px;
  }
  .nft-left-section {
    width: 28%;

    @media screen and (max-width: $xl) {
      width: 40%;
    }

    @media screen and (max-width: $tablet) {
      padding: 0;
      width: 100%;
      margin-top: 1em;
      &__sections {
        margin: 0;
        padding: 0;
        border-bottom: 0;
      }
    }
  }
  .nft-center-section {
    width: 60%;
    padding-left: 3em;
    @media screen and (max-width: $xl) {
      width: 72%;
      padding-left: 0;
    }
    @media screen and (max-width: $tablet) {
      padding: 0;
      width: 100%;
      margin-top: 1.5em !important;
    }
    &__content {
      width: 525px;
      @media screen and (max-width: $tablet) {
        width: 100%;
      }
    }
    .tags-details {
      a {
        color: $color-tertiary;
      }
    }
  }

  .profile-time-ago {
    justify-content: right;
  }
}
.nft-details {
  width: 100%;
  margin-top: 2em;
  margin-left: 50px;
  @media screen and (max-width: $xl) {
    margin: auto;
    margin-top: 1em;
    max-width: 1000px;
  }
  &__image {
    width: 290px;
    @media screen and (max-width: $xl) {
      width: 290px;
    }
  }
  @media screen and (max-width: $tablet) {
    width: 100%;
    padding: 0 !important;
    flex-direction: column;
    &__image {
      margin: 1em auto;
    }
  }
  @media screen and (max-width: $smartphone) {
    &__image {
      width: 100%;
      height: auto;
    }
  }
  .nft-no-link {
    a {
      pointer-events: none;
    }
  }
}
.current-owner-and-activity {
  width: 100%;
  display: flex;
  margin-left: 50px;
  margin-top: 4.5em;
  @media screen and (max-width: $xl) {
    width: 100%;
    margin: auto;
    margin-top: 4.5em;
    max-width: 1000px;
    justify-content: center;
    flex-direction: row-reverse;
  }
  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }
}
.aux-padding-nft {
  padding-left: 9.4em;
  margin-bottom: 5px;
  @media screen and (max-width: $lg) {
    padding-left: 17px;
  }
}

.nft-price {
  left: -4px;
  bottom: 13px;  
  height: 20px;
  display: flex;
  padding: 0 8px;
  align-items: center;
  position: absolute;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background: $color-extra-light-grey;
  img {
    width: 7px;
    height: 12px;
    margin-right: 5px;
  }
}


.timeline-nft-item:first-child{
  padding-top: 23px;
}