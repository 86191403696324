// Colors
@import "./theme/colors";
//variables
@import "./theme/variables";
// Page
@import "./pages/home.page";
@import "./pages/search.page";
@import "./pages/token-characteristics";
@import "./pages/nfts.page";
@import "./pages/nfts-collections.page";
@import "./pages//comming-soon.page";
// Components
@import "./components/buttons";
@import "./components/spinner";
@import "./components/modal";
@import "./components/graph";
@import "./components/badge";
@import "./components/checkbox";
@import "./components/card-list";
@import "./components/footer";
@import "./components/investment-row";
@import "./components/supply-bar";
@import "./components/uniswap-widget";
@import "./components/liquidity-widget";
@import "./components/table-questions";
@import "./components/page-not-found";
@import "./components/address-component";
@import "./components/widgets";
@import "./components/dropdown-menu";
@import "./components/video-tag";
// Page admin
@import "./components/scan-token.page";
@import "./components/gridjs-pagination";
@import "./pages/top-holders";
// Util
@import "./util/reset";
@import "./util/utils";

._37_FJ {
  margin: 0;
  padding: 0;
  border: none;
  top: 20%;
  background: #edfdff;
  padding: 1em;
  display: block;
  animation: box-password 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @keyframes box-password {
    0% {
      transform: translate(-50%, -50%) scale(1.2);

      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
  ._ovIFV {
    background: none;
    color: #04a2b8;
    padding: 0.5em 3em;
    margin: 1em 0;
  }
  ._ovIFV + div {
    padding: 0;
    margin: 0;
  }
  input {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 1rem;
    text-align: center;
    border-radius: 40px;
    border: 1px solid #bbb6b6;
  }
  ._1YwH3 {
    margin: 1em 0;
    padding: 0;
    button {
      cursor: pointer;
      font-size: 1rem;
      color: white;
      border-radius: 40px;
      height: 40px;
      width: 100%;
      display: block;
      background: #04a2b8;
      border: none;
    }
  }
}

.content-profile {
  display: flex;
  justify-content: center;
  &__content {
    width: 350px;
  }
  &__input-content {
    z-index: 2 !important;
    width: 100% !important;
  }
  input[type="email"] {
    height: 40px;
    border-radius: 40px;
    border: 1px solid $color-dark-light;
    padding: 1em;
    width: 100%;
  }
}
.field-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.show-box-search {
  opacity: 1 !important;
}

.content-address {
  margin: 1.5em 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  &__input {
    input {
      &:focus {
        border: 1px solid $color-extra !important;
      }
    }
  }
  p {
    // width: 30%;
  }
  // @media screen and (max-width: $lg) {
  //   display: flex;
  //   p {
  //     width: 35%;
  //   }
  //   div {
  //     width: 65%;
  //   }
  // }
  // @media screen and (max-width: 768px) {
  //   display: block;
  //   margin: 1em 0;
  //   p {
  //     width: auto;
  //     margin: 0.5em 0;
  //   }
  //   div {
  //     width: 100%;
  //   }
  // }
}

.skeleton-circle {
  margin-right: 1em;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  color: #222222 !important;
  background: #efefef;
}

.contract-symbol {
  font-size: 18px !important;
  color: #222222 !important;
}

.content-no-record-token {
  width: 100%;
  display: grid;
  grid-template-columns: calc(43% - 2em) 35% 22%;
  margin-top: 0em;
  margin-left: 2em;
  margin-bottom: 2em;
  @media screen and (max-width: 993px) {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    .u-display-flex.u-justify-center.u-align-items-center {
      margin-top: 3em !important;
    }
    p {
      margin-top: 3em;
      &:last-child {
        margin-bottom: 2em;
      }
    }
  }
}
.trow-content {
  padding-top: 1.5em;
  @media screen and (max-width: 993px) {
    .u-display-flex.u-justify-center.u-align-items-center {
      margin-top: 1em !important;
    }
  }
}
.drag-item {
  .trow-content:nth-child(2) {
    padding-top: 0;
  }
}

.content-table-rp {
  @media screen and (max-width: 993px) {
    margin: 0 !important;
  }
}
.trow-content {
  @media screen and(max-width: 993px) {
    display: flex !important;
    flex-direction: column !important;
    margin: 0 !important;
  }
}

.toolbar-table-rp {
  &__btn-add {
    margin-left: 0;
    justify-content: flex-end;
    button {
      color: $color-primary;
      background: none !important;
      font-family: "Roboto" !important;
    }
  }
  @media screen and(max-width: $smartphone) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    .select-icon-content {
      position: inherit;
      margin: 0;
      margin-top: 0.5em;
      margin-bottom: 0;
      height: 28px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
}

.active-account {
  color: $color-extra;
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
  font-family: "Roboto";
  margin-left: 2em;
  margin-bottom: 0.5em;
  &:before {
    content: "";
    left: -16px;
    top: 2px;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    position: absolute;
    background: $color-extra;
  }
}

.input-create-label {
  label {
    font-size: 16px;
    color: #777777;
    font-family: "Roboto Medium";
    margin-bottom: 0.5em !important;
    display: block;
  }
  p {
    font-size: 13px;
    margin-top: 0.5em;
    margin-left: 0.3em;
  }
  @media screen and (max-width: 620px) {
    width: 100% !important;
    input {
      width: 100% !important;
    }
  }
}

.border-color-secondary {
  border-color: $color-secondary;
}

.no-display {
  display: none !important;
}

.add-action-content {
  margin-top: 2.5em;
  width: 100%;
  &:first-child {
    margin-top: 0;
  }
}

.project-name__tooltip-verify {
  background: #f1f1f1;
  position: absolute;
  z-index: 9;
  display: flex;
  align-items: center;
  padding: 1em 0.8em;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 40%;
  transform: translateY(-50%) translateX(-50%) scale(0);
  left: 50%;
  transition: 0.2s ease all;
  transform-origin: top center;
  p {
    // font-family: "Roboto Light";
    font-size: 0.7rem;
    text-align: center;
    margin-top: 0.3em;
  }
  .text-verify {
    font-size: 0.9rem;

    font-family: "Roboto Medium";
  }
  &--check {
    width: 25px;
    height: 25px !important;
    margin-bottom: 0.2em;
  }
}

.show-verify {
  transform: translateY(-50%) translateX(-50%) scale(1);
}

.project-name__verify--image:hover + {
  .project-name__tooltip-verify {
    transform: translateX(-50%) scale(1);
  }
}

.drag-and-drop {
  font-size: 1.5em !important;
  cursor: pointer;
}

.rotate-arrow {
  transform: rotate(180deg);
  transition: 0.2s ease all;
}
.btn-edit-action {
  font-size: 9px;
  cursor: pointer;
  color: #777777;
  &:hover {
    color: $color-primary-light !important;
  }
}
.table-actions {
  display: flex;
  align-items: center;

  button {
    display: flex;
    margin: 0 0.5em !important;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: none !important;
    svg {
      font-size: 1.5em !important;
    }
  }
  .drag-and-drop {
    margin: 0 0.1em;
    font-size: 1.6em !important;
  }
}
.logo-home-page {
  display: none;
}
@media screen and (max-width: $lg) {
  .logo-home-page {
    display: block;
    margin: auto;
    margin-bottom: 1.3em;
  }
  .logo-image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(0) scale(0);
  }
  .logo-no-home-page {
    position: fixed;
    top: 5px;
    // transform: translateX(-50%) translateY(0) scale(1);
  }
  .nav-bar-scroll-nomain {
    transition: 0.1s ease all;
    position: fixed !important;
    background: white !important;
    // border-bottom: 1px solid $color-secondary-extra-light;
    box-shadow: 0px -15px 20px 0px #6c6c6c;
  }

  @media screen and (max-width: $lg) {
    .nav-bar-scroll {
      background: white !important;
      position: fixed !important;
      transition: 0.1s ease all;
      box-shadow: 0px -15px 20px 0px #6c6c6c;
      .logo-image {
        position: absolute;
        left: 50%;
        top: 5px;
        animation: 0.2s scale-logo 1 forwards;
        transform: translateX(-50%) translateY(-30px) scale(1);
        @keyframes scale-logo {
          to {
            transform: translateX(-50%) translateY(0) scale(1);
          }
        }
      }
    }
  }
}

iframe {
  display: none;
}

.dashboard-right {
  margin-bottom: 2em;
  padding-left: 1.2em;
  @media screen and (max-width: $lg) {
    padding-left: 0;
    padding-left: 0.7em;
    .investment-cards {
      display: none;
    }
  }
}
@media (min-width: $lg) {
  .container {
    max-width: 1150px;
  }
}
@media (min-width: $xl) {
  .container {
    max-width: 1250px;
  }
}

@media (min-width: $xxl) {
  .container {
    max-width: 1350px;
  }
}

.hr-separator {
  width: 93%;
  margin: auto auto 1em;
  border: 0px inset;
  border-bottom: 1px solid #c5c5c5;
}

.content-address-token {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-token-and-name {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.u-text-size-11 {
  font-size: 11px !important;
}

@media screen and (max-width: 993px) {
  .token-only-responsive {
    margin-top: 1em;
    margin-bottom: 1em;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .tcell-content {
    margin-top: 1em;
  }
}
.tcell-content {
  .u-border-bottom-1:last-child {
    border: 0 !important;
  }
}

.id-border-last-child:nth-last-child(2) {
  border-bottom: none !important;
}
.id-border-last-child {
  margin-bottom: 0.3em;
  padding-bottom: 0.2em;
}

small.move-decimal-left {
  left: -2px;
  position: relative;
}

.normal-font-small {
  font-size: 13px;
}

.copyright {
  font-size: 12px;
  color: #777777;
  text-align: center;
  margin: 5em 0 2em 0;
}

// .search-component__search {
//   .search-component__input {
//     img {
//       display: none;
//     }
//   }
// }

// .search-component__search-bar form {
//   img.back-search {
//     display: none;
//   }
// }

// @media screen and(max-width: $lg) {
//   .MuiAutocomplete-popper {
//     top: 60px !important;
//     position: fixed;

//     transform: inherit !important;
//     .MuiAutocomplete-paper {
//       width: 100vw;
//       height: 100vh;
//       height: calc(100vh - 60px);
//       margin: 0 !important;
//       background: $color-contrast;

//       .MuiAutocomplete-listbox {
//         max-height: calc(100vh - 60px);
//       }
//     }
//     .MuiPaper-elevation1 {
//       box-shadow: none;
//       // margin-top: 0.3em !important;
//       border-top: 1px solid #c5c5c5;
//     }
//   }
//   .search-content .all-results{
//     padding: 0 1em;
//   }
//   .top-seach-smartphone {
//     height: auto;
//     .logo-search {
//       display: none;
//     }
//     .MuiAutocomplete-root {
//       // height: 100vh !important;
//     }
//     .search-component__search {
//       position: fixed;
//       top: 0;
//       background: white;

//       padding-top: 1em !important;
//       margin: 0 !important;
//       .search-component__input {
//         img {
//           top: 7px;
//           left: 14px;
//           width: 20px;
//           display: block;
//           position: absolute;
//         }
//         input {
//           padding-left: 2.8em;
//           height: 37px;
//         }
//         svg {
//           font-size: 1em;
//         }
//         .clean-word {
//           top: 11px;
//           right: 45px;
//         }
//       }
//     }
//     .search-component__search-bar form {
//       padding-right: 4em;
//       padding-left: 1em;
//       img.back-search {
//         width: 20px;
//         position: absolute;
//         right: 20px;
//         top: 22px;
//         display: block;
//       }
//     }
//   }
// }

// correct menu top

// @media screen and(max-width: $lg) {
//   .MuiAutocomplete-popper {
//     top: 60px !important;
//     position: fixed;

//     transform: inherit !important;
//     .MuiAutocomplete-paper {
//       width: 100vw;
//       height: 100vh;
//       height: calc(100vh - 60px);
//       margin: 0 !important;
//       background: $color-contrast;

//       .MuiAutocomplete-listbox {
//         max-height: calc(100vh - 60px);
//       }
//     }
//     .MuiPaper-elevation1 {
//       box-shadow: none;
//       margin-top: 0.3em !important;
//       border-top: 1px solid #c5c5c5;
//     }
//     .MuiAutocomplete-option {
//       color: #999;
//       margin-left: 2.5em;
//     }
//   }
// }

.height-search-box {
  top: 10px;
  width: auto;
  height: 70px;
  display: flex;
  position: absolute;
  align-items: center;
  z-index: 999;
  width: 750px;
  padding-right: 5px;
  .nocliking {
    margin-right: 28px;
    @media screen and (max-width: $smartphone) {
      margin-right: 22px;
    }
    img {
      width: 115px;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: $lg) {
    width: 90% !important;
    left: 0;
    height: 65px;
    position: fixed;
    padding-right: 0em;
    background: $color-contrast;
  }

  .search-component__search-bar {
    flex-direction: row;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 7px;
    .logo-search {
      position: relative;
      margin: 0;
      width: 110px !important;
      margin-right: 10px;
      @media screen and (max-width: $lg) {
        display: none;
      }
    }
    @media screen and (max-width: $lg) {
      .back-search {
        display: block !important;
        width: 20px !important;
        position: absolute;
        right: -32px;
        top: 10px;
      }
    }
    form {
      width: auto;
      margin-top: 0;
      flex-direction: row;
      @media screen and (max-width: $lg) {
        width: 100%;
      }
      button {
        display: none;
      }
    }
  }
  img.isotype-logo {
    left: 11px;
    height: 20px;
    width: 20px !important;
    display: none;
    @media screen and (max-width: $lg) {
      display: block;
    }
  }
  .search-component__input {
    width: auto;
    margin-left: 1em;
    @media screen and (max-width: $lg) {
      width: 100%;
      margin-left: 0;
    }
    svg {
      top: 13px;
    }
  }

  input {
    width: 520px;
    height: 42px;
    @media screen and (max-width: $lg) {
      width: 100%;
      height: 37px;
      padding-left: 2.5em;
      padding-right: 3.5em;
    }
  }
  .clean-word {
    top: 14px !important;
    @media screen and (max-width: $lg) {
      right: 35px;
      top: 11px !important;
    }
  }

  .loader-results {
    height: 30px;
    margin-top: 0;
    // transform: scale(1.5);
    margin-bottom: 0;
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
  }
}
.search-component.height-search-box {
  input {
    width: 450px !important;
  }
}
.height-search-box.sticky-search-bar {
  margin: initial;
  a.nocliking {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .logo-search {
    width: 115px;
    margin-bottom: 0;
  }
}

.sticky-search-bar {
  position: sticky;
  background: $color-contrast;
  @media screen and (max-width: $tablet) {
    padding: 0;
    padding-right: 5px;
    z-index: 9999 !important;
  }
}
.fix-search-bar {
  top: 0;
  width: auto;
  height: 85px;
  display: flex;
  position: fixed;
  align-items: center;
  z-index: 999;
  width: 750px;
  padding-right: 5px;
  div {
    width: 100%;
    margin-right: 50px;
  }
  input {
    width: 100% !important;
  }
  .nocliking {
    margin-right: 28px;
    @media screen and (max-width: $smartphone) {
      margin-right: 22px;
    }
    img {
      width: 115px;
      margin-bottom: 0;
    }
  }
}

.button-back-home {
  display: none;
}
@media screen and(max-width: $lg) {
  .MuiAutocomplete-popper {
    top: 60px !important;
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    transform: inherit !important;
    .MuiAutocomplete-paper {
      width: 100vw;
      height: 100vh;
      height: calc(100vh - 65px);
      margin: 0 !important;
      background: $color-contrast;

      .MuiAutocomplete-listbox {
        max-height: calc(100vh - 60px);
      }
    }
    .MuiPaper-elevation1 {
      box-shadow: none;
      margin-top: 0.3em !important;
      border-top: 1px solid #c5c5c5;
    }
    .MuiAutocomplete-option {
      color: #999;
      margin-left: 2.5em;
    }
  }
  .height-search-box {
    top: 10px;
    left: 0px;
    width: 100%;
    z-index: 9999;
    // overflow: hidden;

    border-radius: 0;
    background: #fff;
    .search-component__search-bar {
      width: 100%;
      padding: 1em 0;
      form {
        margin-top: 0;
      }
    }

    .logo-search {
      display: none;
    }
    .back-search {
      display: block;
    }
    .isotype-logo {
      width: 20px;
      display: block;
      position: absolute;
    }

    input {
      padding-left: 2.5em;
    }
    .back-search {
      top: 9px;
      width: 20px;
      right: -27px;
      display: block;
      position: absolute;
    }
    .button-back-home {
      display: block;
      position: absolute;
      z-index: 999;
      top: 0;
      background: none;
      border: none;
      height: 65px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: 0;
      cursor: pointer;
      background: $color-contrast;
      img {
        margin: 0;
        width: 20px;
      }
    }
  }

  .height-box {
    height: 100%;
  }
  .no-height-box {
    height: auto;
  }
}
a.nocliking {
  user-select: none;
  pointer-events: none;
}

.height-search-box {
  a.nocliking {
    pointer-events: all;
  }
}

.menu {
  display: none;
}

.menu.active {
  display: block;
  background: tomato;
}

a.active-menu-search {
  font-family: "Roboto Light";
  font-size: 16px;
  position: relative;
  transition: 0.2s ease all;
  // &::after {
  //   display: block;
  //   content: "";
  //   transform: scaleX(0);
  //   transition: transform 0.2s ease-in-out;
  //   border-bottom: solid 2px $color-primary;
  //   position: absolute;
  //   bottom: -5px;
  //   left: 0;
  //   width: 100%;
  // }
  // &:hover:after {
  //   transform: scaleX(1);
  // }
}

a.active-menu-search {
  text-decoration: underline;
  color: $color-primary !important;
  // color: #222222;
  // font-family: "Roboto" !important;
  // &:after {
  //   transform: scaleX(1);
  // }
}

/* clears the 'X' from Internet Explorer */
input.hide-clear[type="search"]::-ms-clear,
input.hide-clear[type="search"]::-ms-reveal,
.hide-clear input[type="search"]::-ms-clear,
.hide-clear input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input.hide-clear[type="search"]::-webkit-search-decoration,
input.hide-clear[type="search"]::-webkit-search-cancel-button,
input.hide-clear[type="search"]::-webkit-search-results-button,
input.hide-clear[type="search"]::-webkit-search-results-decoration,
.hide-clear input[type="search"]::-webkit-search-decoration,
.hide-clear input[type="search"]::-webkit-search-cancel-button,
.hide-clear input[type="search"]::-webkit-search-results-button,
.hide-clear input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.about {
  width: 680px;
  @media screen and(max-width :$smartphone) {
    width: 100%;
    padding: 0 16px;
  }
  &__title {
    font-size: 1rem;
    margin-bottom: 1.8em;
    font-family: "Roboto Bold" !important;
    @media screen and(max-width :$smartphone) {
      font-size: 16px !important;
    }
  }
  &__description {
    line-height: 25px;
    font-size: 16px;
    color: $color-dark;
    font-family: "Roboto" !important;
    @media screen and(max-width :$smartphone) {
      font-size: 14px !important;
    }
    a {
      margin-top: 1em;
      font-size: 1rem;
      display: inline-flex;
      color: $color-primary;
      font-family: "Roboto Medium";
    }
  }
}

.highlight-sp {
  display: none;
  @media screen and (max-width: $xl) {
    display: block;
    padding-top: 2em;
    border-top: 1px solid $color-dark-dcdc;
  }
  @media screen and (max-width: $tablet) {
    padding-top: 0;
    border-top: none;
    margin-bottom: 2em;
  }
}
.highlight-no-desktop {
  display: block;
  @media screen and (max-width: $xl) {
    display: none;
  }
}

.hidde-content-header {
  @media screen and (max-width: $lg) {
    display: inline-block !important;
    width: 0px !important;
    height: 65px;
    min-width: 0px;
    max-width: 0px;
    padding: 0 !important;
  }
}

.intercom-lightweight-app-launcher {
  transform: scale(0.7) !important;
  bottom: 10px !important;
  right: 10px !important;
}
.intercom-launcher-frame {
  transform: scale(0.7) !important;
}
.intercom-lightweight-app-launcher .intercom-launcher {
  transform: scale(0.7) !important;
}
.intercom-lightweight-app-launcher-icon {
  transform: scale(0.7) !important;
}
.intercom-launcher {
  transform: scale(0.7) !important;
}
.intercom-launcher-frame {
  bottom: 10px !important;
  right: 10px !important;
}
.static-social {
  bottom: 20px;
  right: 75px;
  position: fixed;
  a {
    width: 35px;
    height: 35px;
    margin-left: 15px;
    display: inline-flex;
    align-items: center;
    color: $color-primary;
    justify-content: center;
    font-size: 28px;
    transform: scale(0);
    animation: 0.3s 1 init-scale forwards;
    animation-delay: 0.4s;
    &:hover {
      font-size: 30px;
      transform-origin: center center;
    }
    &:first-child {
      animation-delay: 0.5s;
    }
    @keyframes init-scale {
      100% {
        transform: scale(1);
      }
    }
  }
}

.after-footer {
  margin: auto;
  max-width: 1024px;
  + footer {
    text-align: center !important;
    p {
      font-size: 15px;
    }
  }
}
.size-model-viwer {
  width: 300px;
  height: 300px;
}
model-viewer {
  width: 300px;
  height: 250px;
}
.size-audio-controls {
  width: 300px;
  height: 60px;
}
.logo-for-developers {
  a {
    display: flex;
    align-items: center;
  }
  p {
    font-size: 15px;
    margin-left: 10px;
    color: $color-extra-grey;
  }
  // @media screen and (max-width: $lg) {
  //   width: 300px !important;
  //   a {
  //     width: 300px !important;
  //   }
  // }
}
.header-app-content {
  margin: auto;
  max-width: 1024px;
  margin-top: 0 !important;
  background: $color-contrast;
  margin-bottom: 4em !important;
  a {
    z-index: 99;
    position: relative;
  }
}
.header-wallet-content {
  margin: auto;
  max-width: 100%;
  margin-top: 0 !important;
  margin-bottom: 0em !important;
  a {
    z-index: 99;
    position: relative;
  }
}
@media screen and (max-width: $lg) {
  .header-app-content {
    margin-bottom: 0.5em !important;
  }
}

.text-subtitle-text {
  padding-top: 3em;
  padding-bottom: 2em;
  font-size: 22px !important;
  color: $color-widget-title !important;
  @media screen and (max-width: $tablet) {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }
}

.list-search-menu {
  a:hover {
    color: $color-primary !important;
    text-decoration: underline !important;
  }
}
// $xxl: 1400px;
// $xl: 1200px;
// $lg: 992px;
// $tablet: 870px;
// $smartphone: 580px;
.position-header-content {
  right: -4em;
  position: relative;
  @media screen and (max-width: $xxl) {
    right: -2em;
  }
  @media screen and (max-width: $xl) {
    right: 0em;
  }
}

.max-height-screen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 190px);
  @media screen and (max-width: $tablet) {
    height: 100%;
    padding-top: 1em;
    align-items: flex-start;
  }
}

.max-height-screen-2 {
  @media screen and (max-width: $tablet) {
    height: calc(100vh - 190px);
  }
}

.filter-front-page {
  div:hover {
    background: none;
  }
  span {
    padding-right: 7px !important;
  }
}
.check-asset {
  span {
    color: $color-primary !important;
  }
  p {
    background: $color-primary !important;
  }
}

.no-check-asset {
}

.check-item {
  background: $color-primary-f2e8ff !important;
  span {
    color: $color-primary !important;
  }
}

.collection-timeline {
  .date-collections {
    left: calc(400px - -30px);
  }
  .nft-group-transfers {
    a,
    .img-nft-assets {
      width: 73px;
      height: 73px;
    }
  }
  .activity-date-collection {
    left: calc(395px - 75px);
  }
  .u-hover-box {
    margin-bottom: 0.5em !important;
  }
}

.timeline-design {
  .tag-button {
    height: 16px;
    padding: 0 6px;
    line-height: 16px;
    color: $color-primary !important;
    background-color: $color-primary-f2e8ff !important;
  }
  .profile-address__by {
    color: #999 !important;
  }
}

.no-check-item {
  .MuiChip-label {
    color: $color-primary !important;
    .MuiBadge-badge {
      background-color: $color-primary !important;
    }
  }
  &:hover {
    background: none !important;
    color: $color-primary !important;
  }
  &:focus {
    background: none !important;
  }
}

.columns-of-three {
  img.img-nft-assets {
    width: 204px !important;
    height: 204px !important;
  }
}
.columns-of-four {
  img.img-nft-assets {
    width: 150px !important;
    height: 150px !important;
  }
  div.item-image {
    margin-right: 11px !important;
  }
}
.filter-profile {
  div:nth-child(2) {
    span {
      padding-right: 12px;
      border-right: 1px solid $color-light-grey;
    }
  }
}
