.color-text-77 {
  color: #777777 !important;
}
.card-list {
  width: auto !important;
  opacity: 0;
  animation: show-dashboard-1 0.3s 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both !important;
  @keyframes show-dashboard-1 {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  margin-top: 1em;
  .card-list__table-head {
    display: none;
  }
  .card-list__table-body {
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: 33.33333333% 33.33333333% 33.33333333%;
    // @media screen and (max-width: 1200px) {
    //   grid-template-columns: 50% 50%;
    // }
    // @media screen and (max-width: 768px) {
    //   grid-template-columns: 100%;
    // }
    .card-list__table-body--row {
      padding: 0 0.5em;
      width: 420px;
      border: 5px solid #efefef !important;
      @media screen and (max-width: $xxl) {
        width: 362px;
      }
      @media screen and (max-width: $xl) {
        width: 458px;
      }
      @media screen and (max-width: $lg) {
        width: 338px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .row-grid {
        // padding: 2em 0.5em 1em 0.5em;
        display: flex !important;
        min-height: 390px;
        margin-bottom: 1em;
        flex-direction: column;
        border-bottom: 0;
      }
      .token-symbol {
        justify-content: center !important;
        align-items: center !important;
        img {
          width: 69px !important;
          height: 69px !important;
        }
        &__symbol-token {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 1.5em !important;
        }
      }
      .td-balance {
        justify-content: center;
        margin: 0 !important;
      }
      .td-actions {
        display: flex;
        margin-top: 0.6em;
        margin-bottom: 0.6em;
        justify-content: space-around;
        flex-wrap: wrap;
        &__group {
          width: 50%;
          margin: 0.8em 0 !important;
        }
        .btn-delete-investment {
          position: absolute;
          top: 10px;
          right: 5px;
        }
      }
      .card-list__table-body--row--cell {
        margin: 1.5em 0;
        position: initial !important;
        &:nth-child(2) {
          margin: 0;
        }
        &:nth-child(3) {
          margin: 0;
        }
      }
      .graph {
        &__process {
          position: initial;
          height: 280px;
          width: 1px;
          border-left: none;
          background: #222222;
          &:before {
            left: 50.2%;
            bottom: -12px;
            transform: translateX(-50%);
            top: inherit;
          }
          &:after {
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            top: 0;
          }
        }
        .progress-bar {
          display: flex;
          align-items: flex-end;
          width: auto;
          height: 280px;
          &__inner {
            &:after {
              left: -4px;
              padding: 0.24em;
              bottom: 2px;
            }
            &--total-paid {
              width: max-content;
              right: 25px;
              position: absolute;
            }
          }
          &__circle {
            top: -2px;
            left: 50%;
            transform: translateX(-50%);
            &--text {
              display: flex;
              flex-direction: column;
              align-items: center;
              right: -115px;
              top: initial;
              left: initial;
              &:before {
                left: -15px;
                transform: rotate(90deg);
                top: 9px;
              }
            }
          }
        }
      }
      .graph-text-group {
        display: flex;
        justify-content: center;
        position: relative;
      }
      .graph-text-group__left {
        position: absolute;
        bottom: -37px;
        left: 76px;
        top: inherit;
        align-items: flex-end;
        margin: 0;
        padding: 0;
      }
      .graph-text-group__right {
        left: 34%;
        position: absolute;
        align-items: flex-end;
        top: -30px;
        transform: translateX(-50%);
      }
      .history-content {
        overflow-x: scroll;
      }
      .history-content__table {
        width: 660px;
      }
    }
  }
}

.title-group-investment {
  color: $color-extra-grey !important;
  font-size: 12px !important;
  font-family: "Roboto" !important;
  padding: 2em 1em 0.8em 1.7em;
  position: relative;
  @media screen and (max-width: $lg) {
    padding-bottom: 0 !important;
  }
}
.before-square {
  padding-left: 2em;
  font-size: 12px !important;
  color: #222222 !important;
  &:before {
    content: "";
    left: 12px;
    height: 5px;
    bottom: 15px;
    width: 5px;
    position: absolute;
    background: $color-primary;
  }
  @media screen and (max-width: $lg) {
    &:before {
      bottom: 5px;
    }
  }
}

.card-list {
  .u-padding-top-1 {
    padding-top: 0;
    position: relative;
    padding: 0.3em;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .title-group-investment {
    left: 50px;
    top: 30px;
    color: $color-primary-light;
    font-size: 18px !important;
    font-family: "Roboto Medium" !important;
    position: relative;
    padding-left: 15px;
    position: absolute;
    &:before {
      content: "";
      left: -5px;
      height: 9px;
      top: 5px;
      width: 9px;
      position: absolute;
      background: $color-primary-light;
    }
  }
  .group-investment {
    // box-shadow: inset 0 0 0px 5px #000000;
    border: 5px solid #efefef !important;
    min-height: 400px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    padding-top: 0;

    .border-bottom-group {
      border: none !important;
      &:before {
        height: 0;
      }
    }
    .card-list__table-body--row {
      border: none !important;
    }

    .card-list__table-body--row:last-child .border-bottom-group {
      border-bottom: 0 !important;
    }
    .card-list__table-body--row:first-child .border-bottom-group {
      border-left: 0 !important;
    }
    .card-list__table-body--row .row-grid {
      margin-bottom: 0;
      border: none !important;
      border-left: 1px solid #efefef !important;
      @media screen and (max-width: 768px) {
        border-bottom: 1px solid #efefef !important;
      }
    }
  }
  &__table-body--row {
    padding: 1em 0 !important;

    .row-grid {
      margin: 0 !important;
    }
  }
}

.group-investment {
  .border-bottom-group {
    border: none !important;
    &:before {
      content: "";
      position: absolute;
      left: 22%;
      bottom: 0;
      width: 78%;
      height: 1px;
      background: #cbdfe2;
    }
  }
  .card-list__table-body--row {
    &:last-child {
      .border-bottom-group {
        border-bottom: 1px solid #cbdfe2 !important;
        &:before {
          background: none;
        }
      }
    }
  }
  .investment-container__item {
    // padding-top: 1em !important;
  }
  .investment-container__item:last-child {
    padding-bottom: 0 !important;
  }
}

.investment-container__child {
  .token-investment:last-child {
    .hr-separator {
      display: none;
    }
  }
}

.content-tokenes-investment {
  .no-token-rewards:nth-child(4) {
    .hr-separator {
      display: block;
    }
  }
}
