.top-holder-row {
  padding-bottom: 30px;

  &:hover {
    .tags-details__edit-label {
      display: inline;
    }
  }

  .tags-details__edit-label {
    display: none;
  }

  &__content-item {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  &__left {
    width: 77%;
    display: flex;
    position: relative;
    white-space: nowrap;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      .u-display-flex {
        width: 100%;
        margin-bottom: 0.2em;
      }
    }
    @media screen and (max-width: 480px) {
      width: 70%;
    }
  }
  &__right {
    width: 23%;
    @media screen and (max-width: 480px) {
      width: 30%;
    }
  }
  &__details {
    display: inline;
    // max-width: 65px;
    margin-right: 2px;
    a {
      display: inline-block;
      &:hover {
        transform: scale(1.03);
      }
    }
  }
  &__address {
    font-size: 15px;
    text-decoration: none !important;
    color: $color-tertiary !important;
    @media screen and (max-width: $tablet) {
      font-size: 14px;
    }
    &:hover {
      transform: scale(1.03);
      // color: #6e6e6e;
      // text-decoration: underline;
    }
  }
  &__percentage {
    // width: 150px;
    height: 20px;
    min-width: 45px;
    max-width: 150px;
    font-size: 12px;
    padding: 0 0.5em;
    text-align: right;
    line-height: 1.5em;
    border-radius: 10px;
    display: inline-block;
    color: $color-tertiary;
    background: $color-extra-light-grey;
    border: 1px solid $color-light-grey;
    @media screen and (max-width: $lg) {
      margin-top: 1em;
      margin-left: 2.3em;
    }
  }
  &__total {
    font-size: 12px;
    margin-left: 1em;
    color: $color-tertiary;
    text-transform: uppercase;
  }
}
.item-number {
  width: 20px;
}
.top-holder-row:nth-child(1) {
  .top-holder-row__percentage {
    width: 150px;
  }
}
.top-holder-row:nth-child(2) {
  .top-holder-row__percentage {
    width: 80px;
  }
}

.top-holder-row:nth-child(3) {
  .top-holder-row__percentage {
    width: 60px;
  }
}

.profile-nfts {
  margin: 0.5em 0 1em 0;
  margin-left: 14px;
  img {
    margin: 0 10px;
  }
  button {
    border: none;
    cursor: pointer;
    margin-left: 0.5em;
    background: none;
    font-size: 1.5em;
    color: $color-primary;
  }
}
.tags-details {
  display: block;
  font-size: 15px;
  font-family: "Roboto";
  color: $color-tertiary;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 510px;
  white-space: nowrap;
  @media screen and(max-width: $tablet) {
    font-size: 14px;
  }
  a {

    font-size: 15px;
    display: inline-block;
    color: $color-primary;
    font-family: "Roboto Medium";
    @media screen and(max-width: $tablet) {
      font-size: 14px;
    }
    &:hover {
      transform: scale(1.03);
      
    }
  }
}

.profile-tags {
  margin-left: 0;
  margin: 0px 3px 0 0;

  a {
    font-size: 15px;
    color: $color-primary;
    font-family: "Roboto Medium";
    text-transform: lowercase;
    display: inline-block;
    // transform: 0.1s ease all;
    span {
      margin: 0 2px;
      font-family: "Roboto Medium";
      display: inline-block;
      // transition: .1s ease all;
      &:hover {
        // color: #a268ec;
        // text-decoration: underline;
        transform: scale(1.03);
      }
    }
    &:first-child {
      margin: 0;
    }
    &:last-child {
      span:last-child {
        display: none;
      }
    }
    span:last-child {
      &:hover {
        text-decoration: none;
        transform: none;
      }
    }
  }
  @media screen and (max-width: 480px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    color: $color-primary;

  }
}

.top-up-coming {
  margin-bottom: 3em;
  ul {
    margin-left: 0.8em;
    li {
      margin: 0.5em 0;
      font-size: 12px;
      color: $color-extra-grey;
    }
  }
}

.top-list {
  margin-bottom: 3em;
  ul {
    margin-left: 0.8em;
    li {
      display: flex;
      align-items: center;
      margin: 0.5em 0;
      font-size: 12px;
      color: $color-extra-grey;
      &:nth-child(2) {
        .top-list__address {
          // text-decoration: underline;
        }
      }
    }
  }
  &__address {
    margin: 0 1em;
    font-size: 15px;
    margin-right: 5px;
    color: $color-extra-grey;
    &:hover {
      transform: scale(1.03);
    }
  }

  &__tag {
    color: $color-extra-grey;
    font-family: "Roboto Medium";
    &:hover {
      transform: scale(1.03);
    }
    &::after {
      content: "/";
      margin: 0 0.3em;
    }
    &:last-child {
      &::after {
        content: "";
      }
    }
  }
  .btn-top-all {
    margin-top: 1em;
    margin-left: 1.2em;
  }
}

img.img-nft-assets {
  height: 100px;
  width: 100px;
  object-fit: cover;
  transition: 0.1s ease all;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
}
.widget-characteristics {
  // margin-top: 1.5em;
  .profile-nfts {
    img.img-nft-assets {
      width: 30px;
      height: 30px;
      object-fit: cover;
      margin: 0 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.home-page-search {
  @media screen and (max-width: $lg) {
    margin-top: 0;
  }
  .widget-characteristics {
    margin-top: 1.5em;
    .item-number {
      margin-right: 10px;
    }
    .btn-top-all {
      margin-left: 38px;
    }
    .profile-nfts {
      margin-left: 24px;
    }
  }
}

.widget-characteristics {
  .top-holder-row__details {
    max-width: 70px !important;
  }
  .item-number {
    margin-right: 10px !important;
  }
  .top-holder-row__address {
    text-decoration: underline;
  }
  .profile-nfts {
    margin-left: 25px;
  }
}

.token-characteristics {
  .widget-characteristics {
    .btn-top-all {
      margin-left: 40px;
    }
    .profile-nfts {
      margin-top: 0;
    }
    .top-holder-row__content-item {
      margin-bottom: 5px;
    }
  }
  .user-actively-accumulating {
    .top-holder-row__details {
      max-width: 70px;
      .item-number {
        margin-right: 15px;
      }
      .top-holder-row__address {
        text-decoration: underline;
      }
    }
    .profile-nfts {
      margin-top: 0;
      margin-left: 22px;
    }
    .top-holder-row__content-item {
      margin-bottom: 5px;
    }
    .btn-top-all {
      margin-left: 40px;
    }
  }
  .update-ago {
    margin-top: 0;
  }
}

.container-top-holder {
  .top-holder-row {
    img.img-nft-assets {
      width: 49px;
      height: 49px;
      margin: 0 0.3em;
      object-fit: cover;
    }
  }
  .profile-nfts {
    display: inline-block;
    padding-top: 2px !important;
    margin-left: 46px;
  }

  .loader-nfts {
    margin-left: 2em;
    .skeleton {
      width: 49px !important;
      height: 49px !important;
      margin: 0 0.3em;
    }
  }
  .top-holder-row {
    margin-bottom: 38px;
    &__left {
      width: 55%;
    }
    &__right {
      width: 45%;
    }
  }
}

.widget-characteristics {
  .top-holder-row__details {
    width: auto;
  }
  .profile-tags {
    width: auto !important;
    margin-left: 0;
  }
}

.widget-characteristics {
  .backslash-item {
    // display: none;
  }
}

.home-page-search {
  .widget-characteristics {
    .backslash-item {
      display: none;
    }
  }
}

.top-holder-row__content-item {
  .skeleton {
    &:first-child {
      margin-left: 0;
    }
  }
}
