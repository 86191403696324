.token-characteristics {  
  display: flex;
  margin-left: 0;
  flex-wrap: wrap;
  .profile-nfts {
    display: flex;
  }
  &__users {
    width: 30%;
    padding-left: 2em;
    @media screen and (max-width: $xl) {
      display: none;
    }
    @media screen and (max-width: $tablet) {
      width: 100%;
      padding: 0;
    }
    .user-statistics {
      margin-top: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid #dcdcdc;
      p {
        display: flex;
        align-items: flex-end;
        margin-bottom: 0.5em;
      }
      &__activity {
        font-size: 15px;
        margin-left: 1em;
        color: $color-extra;
        font-family: "Roboto Medium";
        small {
          font-size: 11px;
        }
      }
      &__amount {
        display: flex;
        font-size: 30px;
        color: $color-dark;
        flex-direction: column;
        font-family: "Roboto Medium";
        small {
          font-size: 13px;
          text-align: right;
          color: $color-extra-grey;
        }
      }
      &__price {
        display: flex;
        align-items: baseline;
        p {
          font-size: 30px;
          color: $color-dark;
          font-family: "Roboto Medium";
        }
        span {
          font-size: 15px;
          color: $color-extra;
          font-family: "Roboto Medium";
          svg {
            right: -4px;
            height: 12px;
            position: relative;
          }
        }
      }
    }
  }
  &__symbol-info {
    width: 100%;
    display: flex;
    margin-top: 2em;
    margin-bottom: 2em;
    justify-content: center;
  }
  &__center {
    width: 52%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: $xl) {
      width: 63%;
    }
    @media screen and (max-width: $tablet) {
      width: 100%;
      order: 3;
    }
    .token-activity {
      width: 525px;
      @media screen and (max-width: $tablet) {
        margin-top: 2em;
        width: 100%;
      }
    }
  }
  &__right {
    width: 18%;
    padding-left: 1.5em;
    .token-characteristics__users {
      display: none;
    }
    @media screen and (max-width: $xl) {
      .token-characteristics__users {
        padding: 0;
        display: block;
        order: 2;
        width: 100%;
      }
      display: flex;
      align-items: start;
      justify-content: start;
      flex-direction: column-reverse;
      width: 37%;
    }
    @media screen and (max-width: $tablet) {
      width: 100%;
      padding: 0;
    }
  }

  .profile-nfts {
    margin-left: 0;
    img {
      margin: 0 0.5em;
      &:first-child {
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 870px){

    .collapse-box{
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.token-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 2em;
  padding-top: 1em;
  &__left {
    display: inherit;
    align-items: inherit;
    img {
      width: 35px;
      height: auto;
      object-fit: cover;
    }
    p {
      font-size: 18px;
      margin-left: 1em;
      color: $color-dark;
      font-family: "Roboto Medium";
    }
    @media screen and (max-width: $lg) {
      p {
        font-size: 16px;
      }
      img {
        width: 30px;
        height: auto;
      }
    }
  }
  &__right {
    p {
      font-size: 12px;
      padding-right: 4px;
      color: $color-extra-grey;
    }
    @media screen and (max-width: $lg) {
      display: none;
    }
  }
}
.title-of-section {
  font-size: 12px;
  color: #747474;
  font-family: "Roboto Medium";
}
.top-holder-row {
  &__content-item {
    display: flex;
    flex-wrap: wrap;
  }
  &__left {
  }
  &__details {
    display: inline;
  }
  &__address {
    font-size: 15px;
    color: $color-tertiary;
    // transition: .1s ease all;
    font-family: "Roboto Medium";
    &:hover {
      transform: scale(1.03);
      // text-decoration: underline;
    }
  }
  &__tags {
    display: inline-block;
    a {
      font-size: 15px;
      color: $color-primary;
      font-family: "Roboto Medium";
      &::after {
        content: "/";
        margin: 0 0.3em;
      }
      &:last-child {
        &::after {
          content: "";
        }
      }
    }
    @media screen and (max-width: $lg) {
      margin-left: 1.8em;
      margin-top: 0.5em;
    }
  }
  &__total-tokens {
    width: 140px;
    height: 20px;
    min-width: 45px;
    max-width: 150px;
    font-size: 15px;
    padding: 0 0.5em;
    text-align: right;
    line-height: 1.5em;
    border-radius: 10px;
    display: inline-block;
    color: $color-dark;

    @media screen and (max-width: $lg) {
      margin-top: 1em;
      margin-left: 2.3em;
    }
  }
  &__total {
    font-size: 15px;
    margin-left: 1em;
    color: $color-extra-grey;
  }
  &__nft {
    margin: 0.5em 0 1em 0;
    margin-left: 1.4em;
    // @media screen {

    // }
    img {
      width: 55px;
      height: 49px;
      margin: 0 0.5em;
      object-fit: cover;
    }
    button {
      border: none;
      cursor: pointer;
      margin-left: 0.5em;
      background: none;
      font-size: 1.5em;
      color: $color-primary;
    }
  }
}

.activity-profile {
  width: 525px;
  @media screen and (max-width: $tablet) {
    width: 100%;
    margin-top: 2em;
    padding: 0;
  }
  &__emoji {
    display: flex;
    font-size: 30px;
    margin-left: 2em;
    margin-top: 0.8em;
    span {
      margin-left: 0.5em;
      font-family: "Roboto Medium";
    }
  }
  &__logs-activity {
    ul {
      li {
        display: flex;
        margin: 0.7em 0;
        justify-content: space-between;
        span:first-child {
          font-size: 15px;
          color: $color-tertiary;
        }
        span {
          font-size: 15px;
          color: $color-extra-grey;
        }
      }
    }
  }

  .top-holder-row {
    .profile-nfts {
      img {
        object-fit: cover;
      }
    }
  }
  .profile-nfts {
    margin: 0.4em 0 0.1em 0;
    img {
      margin-left: 0;
    }
  }
  .loader-nfts {
    margin-left: 0.8em;
    .skeleton {
      width: 65px !important;
      height: 65px !important;
    }
  }
  .profile-tags-gray {
    overflow: inherit;
  }
}

.progress-line-bar {
  width: 220px;
  height: 10px;
  margin: 1em 0;
  .progress {
    width: 96%;
    height: 10px;
    position: relative;
    border-radius: 10px;
    background: #b38ae8;
    .value-progress {
      top: -4px;
      left: 105%;
      font-size: 13px;
      position: absolute;
      width: max-content;
      color: $color-tertiary;
    }
  }
}
