.widget-characteristics {
  width: 100%;
  .loader-tags {
    .skeleton {
      width: 35px !important;
      height: 10px !important;
    }
  }
  .loader-nfts {
    .skeleton {
      height: 30px !important;
      width: 30px !important;
      &:first-child {
        margin-left: 0 !important;
      }
      margin-left: 5px !important;
    }
  }

  .top-holder-row__details {
    span {
      font-size: 12px;
    }
    a {
      font-size: 13px;
    }
  }
  .profile-tags {
    a {
      font-size: 13px;
    }
  }
  .top-holder-row {
    margin-bottom: 15px;
    margin-left: 1em;
  }
  .top-holder-row__left {
    width: auto;
  }
  .loader-nfts {
    margin-bottom: 0;
  }
  .btn-top-all {
    margin-left: 30px;
  }

  .top-holder-row__address {
    margin-left: 0.3em;
    margin-right: 0.8em;
  }

  .top-holder-row__content-item {
    justify-content: flex-start;
  }
  .profile-tags {
    margin-right: 0;
  }

  @media screen and (max-width: $xl) {
    order: 1;
  }
}
.title-only-smartphone {
  display: none;
  @media screen and (max-width: $tablet) {
    display: block;
  }
}
.gainers-tag {
  font-size: 13px;
  color: #63ae28;
  padding-top: 2px;
  margin-left: 1em;
  width: auto !important;
  font-family: "Roboto";
}
.user-actively-accumulating {
  width: 100%;
  order: 0;
  .collapse-box {
    border: none;
  }
  @media screen and (max-width: $tablet) {
    margin-top: 0;
  }
  .loader-tags {
    .skeleton {
      width: 35px !important;
      height: 10px !important;
    }
  }
  .loader-nfts {
    .skeleton {
      height: 30px !important;
      width: 30px !important;
    }
  }
  .profile-tags {
    a {
      font-size: 13px;
    }
  }
  .top-holder-row__details {
    span {
      font-size: 12px;
    }
    a {
      font-size: 13px;
    }
  }
  .profile-nfts {
    img {
      width: 30px;
      height: 30px;
      margin: 0 0.2em;
      object-fit: cover;
    }
  }
  .top-holder-row {
    margin-bottom: 15px;
    margin-left: 1em;
  }

  .loader-nfts {
    margin-bottom: 0;
  }

  .top-holder-row__right {
    &--tag {
      font-size: 12px;
      font-family: "Roboto";
      color: $color-tertiary;
    }
  }
  .top-holder-row {
    &__abreviature {
      font-size: 12px;
      cursor: pointer;
      color: $color-primary;
      transform: 0.1s ease all;
      font-family: "Roboto Medium";
      &:hover {
        color: #a268ec;
        text-decoration: underline;
      }
    }
    &__time-ago {
      font-size: 12px;
      margin-left: 1.5em;
      font-family: "Roboto";
      color: $color-extra-grey;
    }
  }

  .top-holder-row__left {
    flex-wrap: wrap;
    width: 100%;
    .box-bought {
      width: 50%;
      padding-left: 25px;
      margin-top: 3px;
    }
    .box-bought-ago {
      width: 47%;
      text-align: right;
    }
  }
  .profile-nfts {
    margin-left: 22px;
    img {
      &:first-child {
        margin-left: 3px;
      }
    }
  }
}

.show-only-one-label {
  .profile-tags {
    a {
      display: none;
      &:first-child {
        // margin-right: 1em;
        display: inline-block;
        &::after {
          content: "";
          margin: 0;
        }
      }
    }
  }
}

.title-header-widget {
  font-size: 15px;
  margin-bottom: 0.8em;
  font-family: "Roboto Medium";
  a {
    color: $color-primary;
    font-family: "Roboto Medium";
  }
}

.popular-topics {
  padding-right: 20px;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      cursor: pointer;
      font-size: 12px;
      margin-right: 1em;
      padding: 3px 1em;
      margin-bottom: 1em;
      color: $color-dark;
      border-radius: 30px;
      transition: 0.1s ease all;
      border: 1px solid #c5c5c5;
      min-height: 25px;
      display: flex;
      align-items: center;
      a {
        color: $color-dark;
      }
      &:hover {
        color: #333333;
        background: #f1f1f1;
      }
    }
  }
}

.home-activities {
  width: 525px;
  @media screen and (max-width: $tablet) {
    width: 100%;
  }
  .top-holder-row__right {
    &--tag {
      font-size: 15px;
      font-family: "Roboto";
      color: $color-tertiary;
    }
  }
  .top-holder-row {
    &__abreviature {
      font-size: 15px;
      cursor: pointer;
      color: $color-primary;
      font-family: "Roboto Medium";
      &:hover {
        color: #a268ec;
        text-decoration: underline;
      }
    }
    &__time-ago {
      font-size: 15px;
      margin-left: 1.5em;
      font-family: "Roboto";
      color: $color-extra-grey;
    }
  }
  .top-holder-row__address {
    margin-left: 0;
  }
  .profile-nfts {
    margin-left: 0;
    margin: 0.9em 0 1em 0;
    img {
      margin-left: 0;
    }
  }
  .loader-nfts {
    margin-left: 0;
    .skeleton {
      margin-left: 0.5em;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  @media screen and (max-width: $tablet) {
    margin-top: 1.5em;
    margin-bottom: 40px;
  }
  .loader-tags {
    margin-left: 10px;
  }
}

.top-holder-row__left {
  flex-direction: column;
  align-items: flex-start;
  .profile-tags {
    a {
      padding: 3px 5px;
      margin-left: 5px;
      border-radius: 10px;
      font-size: 12px !important;
      font-family: "Roboto Medium" !important;
      background: $color-extra-light-grey !important;
    }
    .backslash-item {
      display: none;
    }
  }
  .tags-details {
    margin-top: 3px;
    @media screen and (max-width: $tablet) {
      width: 300px;
    }
  }
}

.top-holder-row {
  .loader-nfts {
    .skeleton {
      margin: 0;
      margin-left: 10px;
    }
  }
}
.profile-tags-gray {
  font-size: 15px;
  font-family: "Roboto";
  color: $color-tertiary;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: auto !important;
  overflow: hidden;
  span {
    margin-right: 0.3em;
  }
  a {
    color: $color-primary;
    font-family: "Roboto Medium";
    display: inline-block;
    &:hover {
      transform: scale(1.03) !important;
    }
  }
}
.profile-time-ago {
  display: block;
  font-size: 15px;
  margin-left: 1em;
  text-align: right;
  font-family: "Roboto";
  color: $color-extra-grey;
  @media screen and (max-width: $tablet) {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.token-characteristics {
  .profile-time-ago {
    width: auto;
    // @media screen and (max-width: $xxl) {
    //   width: 180px;
    // }
  }
}
.home-activities {
  .profile-time-ago {
    width: auto;
  }
}

.token-list {
  @media screen and(max-width: $tablet) {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &__row {
    width: 100%;
    display: flex;
    cursor: pointer;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0.5em 0.75em;

    &:hover {
      background: #f1f1f1;
    }
    @media screen and (max-width: $tablet) {
      width: max-content;
      &:hover {
      }
    }
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
    &.no-link {
      cursor: unset;
      &:hover {
        background: none;
      }
    }
  }
  &__symbol {
    width: 90px;
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    @media screen and (max-width: $xl) {
      width: 80px;
    }
    span {
      &:first-child {
        font-size: 15px;
        overflow: hidden;
        color: $color-dark;
        padding-right: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: "Roboto Medium";
      }
      &:nth-child(2) {
        font-size: 12px;
        font-family: "Roboto";
        color: $color-tertiary;
      }
    }
  }
  &__amount {
    width: 80px;
    display: flex;
    flex-direction: column;
    span {
      &:first-child {
        font-size: 15px;
        font-family: "Roboto";
        color: $color-dark;
      }
      &:nth-child(2) {
        font-size: 12px;
        font-family: "Roboto";
        color: $color-tertiary;
      }
    }
  }
  &__percentage {
    font-size: 13px;
    padding-bottom: 15px;
    font-family: "Roboto Medium";
    width: 55px;
    text-align: right;
    white-space: nowrap;
    @media screen and (max-width: $xl) {
      width: 35px;
    }
    @media screen and (max-width: 1070px) {
      width: 30px;
    }
  }
  .gain-color {
    color: $color-extra;
  }
  .color-no-gain-lost {
    color: $color-tertiary;
  }
  .lost-color {
    color: $color-danger;
  }
  .btn-top-all {
    margin-top: 2em;
    margin-left: 0.75em;
  }
}

.nft-widget {
  @media screen and (max-width: $xl) {
    .collapse-box {
      border-bottom: 1px solid #dcdcdc;
    }
  }
  @media screen and (max-width: $tablet) {
    margin: 0;
    padding: 0;
    border: none;
  }
  &__row {
    display: flex;
    cursor: pointer;
    padding: 0.5em 0.75em;
    &:hover {
      background: #f1f1f1;
    }
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
  }
  &__text {
    color: $color-dark;
    display: flex;
    margin-left: 1.5em;
    flex-direction: column;
    span:first-child {
      font-size: 15px;
      color: $color-dark;
      margin-bottom: 0.4em;
      word-break: break-all;
      font-family: "Roboto Bold";
    }
    span:nth-child(2) {
      font-size: 15px;
      color: $color-dark;
      font-family: "Roboto";
    }
    span:nth-child(3) {
      font-size: 13px;
      font-family: "Roboto Medium";
    }
  }
  .btn-top-all {
    margin-left: 0.75em;
  }
}

.update-ago {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  font-size: 12px;
  padding-top: 22px;
  text-align: right;
  color: $color-extra-grey;
  justify-content: flex-end;
  @media screen and (max-width: $tablet) {
    font-size: 10px;
    padding-top: 16px;
  }
}
.profile-user {
  display: flex;
  margin: 3em auto 5em auto;
  padding: 0 1.5em;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $tablet) {
    margin-bottom: 2em;
    flex-direction: column;
  }
  &__left {
    display: flex;
    align-items: flex-start;
    margin-left: 3em;
    @media screen and (max-width: $tablet) {
      flex-direction: column;
      text-align: center;
      align-items: center;
      margin-left: 0;
    }
  }
  &__avatar {
    width: 70px;
    height: 70px;
    display: flex;
    border-radius: 50%;
    margin-right: 1.5em;
    align-items: center;
    justify-content: center;
    border: 1px solid #f3f3f3;
    background-color: #fafafa;

    span {
      font-size: 2em;
    }
    @media screen and (max-width: $tablet) {
      margin-right: 0;
      width: 41px;
      height: 41px;
      margin-bottom: 0.5em;
      span {
        font-size: 1.3em;
      }
    }
  }
  &__info {
    span:first-child {
      font-size: 13px;
      color: $color-tertiary;
      font-family: "Roboto Medium";
      @media screen and (max-width: $tablet) {
        font-size: 10px;
      }
    }
    .active-since {
      font-size: 11px;
      color: $color-tertiary;
      @media screen and (max-width: $tablet) {
        font-size: 10px;
      }
    }
    &--links {
      max-width: 320px;
      a .MuiChip-root {
        cursor: pointer;
        height: 25px;
        border-radius: 20px;
        margin: 4px 8px 4px 0;
        background: #f5f5f5;
        border: 1px solid transparent;
        &:hover {
          border-color: $color-primary;
        }
        .MuiChip-label {
          font-size: 14px;
          font-family: "Roboto Medium";
          color: $color-primary;
        }
        @media screen and (max-width: $tablet) {
          font-size: 15px;
        }
      }
      a:last-child .MuiChip-root {
        margin-right: 0;
      }
    }
  }
  &__amount {
    margin-left: 3em;
    display: flex;
    flex-direction: column;
    span:first-child {
      font-size: 30px;
      text-align: right;
      color: $color-dark;
      font-family: "Roboto Medium";
      @media screen and (max-width: $tablet) {
        font-size: 20px;
      }
    }
    span:nth-child(2) {
      font-size: 15px;
      color: $color-extra;
      font-family: "Roboto Medium";
    }
    @media screen and (max-width: $tablet) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.interacts-lot-with {
  margin-bottom: 2em;
  margin-top: 2em;
  &__list {
    padding-left: 13px;
    font-size: 13px;
    color: $color-tertiary;
    li {
      margin-bottom: 1em;
      a {
        color: $color-tertiary;
      }
      a:nth-child(2) {
        margin-left: 0.2em;
        font-family: "Roboto Medium";
        color: $color-primary;
      }
      img {
        width: auto;
        height: 30px;
        margin: 0 0.2em;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  &__nfts {
    margin-top: 0.7em;
  }
}

.has-voted-in {
  ul {
    li {
      font-size: 13px;
      margin-bottom: 1em;
      color: $color-tertiary;
      a {
        line-height: 1.6em;
      }
    }
  }
  &__list {
    padding-left: 13px;
  }
}

.profile-info {
  display: flex;
  justify-content: center;
  margin-bottom: 5em;
  margin-top: 2em;
  .loader-tags {
    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }
  .skeleton {
    margin: 0 0.6em !important;
  }
  .skeleton:first-child {
    width: 80px !important;
    height: 80px !important;
    overflow: hidden;
    border-radius: 50px !important;
    @media screen and (max-width: $tablet) {
      width: 41px !important;
      height: 41px !important;
    }
  }
  .skeleton-group {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .skeleton {
      margin: 5px 0 !important;
    }
    .skeleton:first-child {
      width: 250px !important;
      height: 15px !important;
      border-radius: 0 !important;
    }
    .skeleton:nth-child(2) {
      width: 200px !important;
      height: 15px !important;
    }
    .skeleton:nth-child(3) {
      width: 150px !important;
      height: 15px !important;
    }
    @media screen and (max-width: $tablet) {
      margin-left: 0;
      margin-top: 5px;
      align-items: center;
      .skeleton:first-child {
        height: 10px !important;
      }
      .skeleton:nth-child(2) {
        height: 10px !important;
      }
      .skeleton:nth-child(3) {
        height: 10px !important;
      }
    }
  }

  &__nfts {
    .loader-tags {
      margin: 0.5em 0;
      @media screen and (max-width: $tablet) {
        width: 100%;
      }
    }
    .skeleton:first-child {
      overflow: hidden;
      width: 35px !important;
      height: 35px !important;
      border-radius: 50% !important;
      margin-right: 1em !important;
    }
    .skeleton:nth-child(2) {
      width: 170px !important;
      height: 30px;
    }
  }
}
.nft-widget {
  .loader-tags {
    .skeleton:first-child {
      width: 60px !important;
      height: 60px !important;
      border-radius: 0 !important;
    }
  }
}
.home-page-search {
  display: flex;
  flex-wrap: wrap;
  // margin-left: auto;
  // margin-right: auto;
  padding-top: 64px;
  @media screen and (max-width: $tablet) {
    flex-direction: column-reverse;
  }

  &__left {
    width: 27%;
  }
  .popular-topics-left {
    .collapse-box {
      border: none;
    }
    @media screen and (max-width: $xl) {
      display: none;
    }
  }
  .popular-topics-right {
    width: 100% !important;

    @media screen and (max-width: $xl) {
      display: block;
    }
    @media screen and (max-width: $tablet) {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &__center {
    width: 52%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: $xl) {
      width: 60%;
      justify-content: flex-start;
    }
    @media screen and (max-width: $lg) {
      width: 58%;
      justify-content: flex-start;
      .home-activities {
        // width: 500px;
      }
    }
    @media screen and (max-width: $tablet) {
      width: 100%;
      justify-content: center;
    }
  }
  &__right {
    width: 21%;
    padding-left: 1.5em;
    @media screen and (max-width: $xl) {
      width: 40%;
    }
    @media screen and (max-width: $lg) {
      width: 42%;
      justify-content: flex-start;
    }
    @media screen and (max-width: $tablet) {
      width: 100%;
      padding-left: 0;
      justify-content: center;
    }
  }
  @media screen and (max-width: $xl) {
    justify-content: center;
  }
}

.bubble-widget-chart {
  &.highcharts-data-labels {
    .highcharts-data-label .highcharts-data-label-tag-item > div {
      color: #999999;
      font-family: "Roboto Medium";
      font-weight: 500;
      cursor: pointer;
    }
    &.highcharts-series-hover
      .highcharts-data-label
      .highcharts-data-label-tag-item:hover
      > div {
      color: #8f4ae7 !important;
    }
    &.highcharts-series-hover
      .highcharts-data-label
      .highcharts-data-label-tag-item.hover
      > div {
      color: #8f4ae7 !important;
    }
  }
  .highcharts-halo.highcharts-color-0 {
    display: none;
  }
  .highcharts-point.highcharts-color-0:hover {
    cursor: pointer;
  }
}

// @media (min-width: $xxl) {
//   .home-page-search {
//     max-width: 1251px !important;
//   }
// }
// @media (min-width: $xl) {
//   .home-page-search {
//     max-width: 1251px !important;
//   }
// }

.text-skeleton {
  width: 100%;
  display: flex;
  margin-bottom: 0.5em;
  .skeleton {
    margin-left: 1em;
    &:first-child {
      margin-left: 0;
    }
  }
}

.profile-portfolio {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4em;
  @media screen and (max-width: 768px) {
    flex-direction: row-reverse;
  }
  .nft-widget {
    .btn-top-all {
      margin-top: 2em;
      margin-left: 0.75em;
    }
    .token-list {
      .btn-top-all {
        margin-top: 2em;
        margin-left: 0.75em;
      }
    }
  }
  .tags-details {
    width: 78%;
    @media screen and (max-width: $lg) {
      width: 70%;
    }
    @media screen and (max-width: 450px) {
      width: 58%;
    }
  }
  &__left {
    width: 27%;
    padding-left: 3.5em;
    .left-characteristics {
      display: none;
    }
    @media screen and (max-width: $xxl) {
      width: 30%;
    }
    @media screen and (max-width: $xl) {
      width: 37%;
      .left-characteristics {
        display: block;
      }
    }
    @media screen and (max-width: $tablet) {
      padding: 0;
      width: 100%;
    }
  }
  &__center {
    width: 52%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: $xl) {
      width: 63%;
    }
    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }
  &__right {
    width: 21%;
    @media screen and (max-width: $xxl) {
      width: 18%;
    }
    @media screen and (max-width: $xl) {
      display: none;
    }
  }
}
.left-characteristics {
  .collapse-box {
    border: none;
  }
}
.empty-data {
  font-size: 15px;
  font-family: "Roboto";
  color: $color-extra-grey;
}

.loader-activity-profile {
}

.box-bought {
  font-size: 12px;
  font-family: "Roboto";
  color: $color-tertiary;
}
.box-bought-ago {
  font-size: 12px;
  font-family: "Roboto";
  color: $color-extra-grey;
  margin-left: 5px;
}

.collapse-box {
  z-index: 2;
  position: relative;
  padding-top: 0.5em;
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #dcdcdc;
}

@media screen and (max-width: $tablet) {
  .collapse-box {
    padding-top: 0.5em;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #dcdcdc;
  }
  .collapse-active {
    display: block;
  }
}

.gain-color {
  color: $color-extra;
}
.color-no-gain-lost {
  color: $color-tertiary;
}
.lost-color {
  color: $color-danger;
}

.title-page {
  .profile-nfts {
    margin-left: 0;
    a:first-child {
      img {
        margin-left: 0;
      }
    }
  }
  .top-holder-row {
    width: 80%;
    margin: auto;
    padding-bottom: 30px;
  }
  img.img-nft-assets {
    height: 65px;
    width: 65px;
  }
}

.tab-component {
}
