.container-top-holder {
  padding-left: 9.5em;
  margin-bottom: 3em;
  .item-number {
    font-size: 18px;
    color: $color-tertiary;
    font-family: "Roboto Medium";
    width: 50px;
    display: inline-block;
  }
  &__left {
    width: 70%;
  }
  &__right {
    width: 30%;
  }
}
@media screen and (max-width: $lg) {
  .top-holder-widgets {
    display: none;
  }
}

.top-characteristics {
  display: none;
  cursor: pointer;
  font-size: 12px;
  padding: 0.8em 0;
  color: $color-primary;
  background: #fafafa;
  align-content: center;
  justify-content: center;
  svg {
    font-size: 1.2em;
    margin-left: 0.5em;
  }
  @media screen and (max-width: $lg) {
    display: flex;
  }
}
.show-characteristics {
  display: block;
  padding: 0.8em 1.5em;
  background: #fafafa;
}
.token-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 2em;
  @media screen and (max-width: $tablet){
    margin-bottom: 1em;
  }
  &__left {
    display: inherit;
    align-items: inherit;
    img {
      width: 35px;
      height: auto;
    }
    p {
      font-size: 18px;
      margin-left: 1em;
      color: $color-dark;
      font-family: "Roboto Medium";
    }
    @media screen and (max-width: $lg) {
      p {
        font-size: 16px;
      }
      img {
        width: 30px;
        height: auto;
      }
    }
    @media screen and (max-width: $tablet) {
      flex-direction: column;
      p {
        margin-left: 0;
        margin-top: 1em;
        font-size: 18px;
      }
      img {
        width: 33px;
        height: auto;
      }
    }
  }
  &__right {
    margin-bottom: 2em;
    p {
      font-size: 12px;
      padding-right: 4px;
      color: $color-extra-grey;
    }
    @media screen and (max-width: $lg) {
      display: none;
    }
  }
}

.loader-tags {
  display: inline-flex;
  align-items: center;
  .skeleton {
    margin: 0 0.2em;
  }
}

.loader-nfts {
  margin-left: 1.3em;
  margin-top: 0.5em;
  margin-bottom: 1em;
  display: inline-flex;
  align-items: center;
  .skeleton {
    margin: 0 0.5em;
  }
}
