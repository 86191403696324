.collapse-row {
  display: none !important;
  transition: 3s ease all;
  width: 100%;
  @media screen and (max-width: $lg) {
    margin-top: 1em;
  }
  .cell-content {
    background: #fafafa;
    transform: rotateX(90deg);
    transform-origin: top;
    opacity: 0;
    padding: 1.5em 1.5em 1em;
    width: 99.9%;
    &__vesting-detail {
      margin-bottom: 2em;
      @media screen and (max-width: $lg) {
        margin-bottom: 3.5em;
      }

      &--title {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 0.3em;
        color: #666;
        font-family: "Roboto Medium";
      }
      &--progress {
        font-family: "Roboto Medium";
        margin-left: 2em;
        .percentage {
          margin-right: 0.3em;
          font-size: 14px;
          color: $color-primary;
          font-family: "Roboto Medium";
          &--progress {
            font-size: 12px;
            color: $color-extra-grey;
            font-family: "Roboto Medium";
          }
        }
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .graph-text-group {
    // padding: 0 1em;
    .graph {
      margin: 1em 0;
      &__process {
        width: 100%;
        // &:before {
        //   left: -13px;
        //   z-index: 99;
        // }
        // &:after {
        //   z-index: 99;
        //   right: -13px;
        // }
      }
      .progress-bar__circle {
        width: 13px;
        height: 13px;
        top: -5px;
      }
    }
    .progress-bar__circle--text {
      &::before {
        display: none;
      }
    }
    .progress-bar__inner--total-paid {
      padding-top: 5em !important;
    }
    .progress-bar__circle--text {
      top: 30px;
    }
    position: relative;
    .graph-text-group__left {
      position: absolute !important;
      top: -75%;
      left: -10px;
    }

    .graph-text-group__right {
      position: absolute !important;
      top: -75%;
      right: -10px;
    }
  }
}

.show-row {
  display: flex !important;
  transition: 0.2s ease all;
  .cell-content {
    animation: 0.2s 0.1s down-scale forwards;
    @keyframes down-scale {
      to {
        transform: rotateX(0);
        transform-origin: top;
        opacity: 1;
        top: -0.5px;
        position: relative;
      }
    }
  }
}

.graph {
  display: flex;
  flex-direction: column;
  &__process {
    position: absolute;
    width: calc(100% - 210px);
    border-bottom: 1px solid $color-extra-grey;
    &:before {
      position: absolute;
      border-radius: 50%;
      content: "";
      height: 11px;
      width: 11px;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid $color-extra-grey;
      background-color: $color-contrast;
    }
    &:after {
      position: absolute;
      border-radius: 50%;
      content: "";
      height: 12px;
      width: 12px;
      right: -11.5px;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid $color-extra-grey;
      background-color: $color-contrast;
    }
  }
}
.graph-text-group {
  display: flex;
  align-items: center;

  &__left {
    position: relative;
    width: 130px;
    display: flex;
    flex-direction: column;
    font-size: 12px !important;
    span {
      font-family: "Roboto";
      color: $color-extra-grey;
    }
  }
  &__right {
    position: relative;
    margin-left: 1.9em;
    display: flex;
    flex-direction: column;
    align-items: end;
    color: $color-extra-grey !important;
    span {
      color: $color-extra-grey;
      font-size: 12px;
      font-family: "Roboto";
      width: max-content;
    }
  }
}

.dates {
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
  flex-wrap: wrap;
  p {
    text-align: center;
    width: calc(100% / 6);
    position: relative;
    &::before {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      border-radius: 50%;
      left: 50%;
      bottom: -55px;
      border: 1px solid $color-dark;
      transform: translateX(-50%);
      background: $color-contrast;
    }
  }
  .active-date {
    &::before {
      width: 17px;
      height: 17px;
      border: none;
      background: $color-primary;
    }
  }
}
.amount {
  display: flex;
  margin-top: 3em;
  flex-wrap: wrap;
  justify-content: space-around;
  p {
    width: calc(100% / 6);
    text-align: center;
  }
}

.history-content {
  table {
    font-size: 11px;
    thead {
      tr {
        th {
          text-align: left;
          padding: 0.15em 0em;
          font-weight: 100;
          color: $color-extra-grey;
        }
      }
    }
    tbody {
      tr {
        td {
          text-align: left;
          padding: 0.15em 0em;
          &:nth-child(4) {
            width: 42%;
          }
          p {
            font-size: 11px;
          }
          text-transform: capitalize;
        }
      }
    }
  }
}

.button-outline-icon {
  font-size: 1.5em !important;
  color: $color-primary !important;
  background: none !important;
}

.graph-content-card {
  display: none;
  overflow: hidden;
  height: 0;
  max-height: 0;
  margin-top: 2em;
  border-top: 1px solid $color-secondary-extra-light;
  &__graph {
    display: flex;
    margin: 2em 0;
    margin-left: 4em;
    &--line-status {
      width: 10px;
      height: 330px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 1px solid $color-dark;
      span {
        position: relative;
        left: -8px;
        width: 15px;
        height: 15px;
        display: block;
        border-radius: 50%;
        background: $color-contrast;
        border: 1px solid $color-dark;
      }
    }
  }
  .active-date {
    border: none;
    background: $color-primary;
  }
  .active-text-date {
    font-weight: bold;
  }
  &__dates {
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__amount {
    margin-left: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__space-text {
    margin-left: 3em;
    margin-top: 2em;
  }
}

// .show-schedule-card {
//   display: block !important;
//   animation: 0.2s down-scale-card forwards;
//   @keyframes down-scale-card {
//     to {
//       height: 500px;
//       max-height: 500px;
//     }
//   }
// }
.graph-width-100 {
  width: 100%;
}
.progress-bar {
  background-color: none;
  height: 3px;
  width: 100%;
  border-radius: 8px;
  &__circle {
    right: -12.8px;
    @media screen and (max-width: $lg) {
      right: -12.5px;
    }
    top: -4.9px;
    width: 13px;
    height: 13px;
    background: $color-primary;
    position: absolute;
    border-radius: 50%;
    // top: 50%;
    // transform: translateY(-50%);
    &--text {
      font-size: 14px !important;
      position: absolute;
      width: max-content;
      top: -45px;
      cursor: pointer;
      left: 50%;
      font-family: "Roboto Medium";
      transform: translateX(-50%);
      // padding-right: 2em;
      @media screen and (max-width: $lg) {
        display: none;
      }
      b {
        padding-left: 0.3em;
        font-size: 14px;
        text-decoration: none !important;
      }
      &:before {
        content: "";
        height: 20px;
        left: 50%;
        width: 1px;
        position: absolute;
        background-color: $color-primary;
        transform: translateX(-50%);
        top: 35px;
      }
      a {
        display: inline-block;
        color: $color-primary;
        span {
          margin-left: 0.3em;
          font-size: 18px !important;
          font-family: "Roboto Medium";
          padding-bottom: 0.01em !important;
          border-bottom: 1px solid $color-primary !important;
        }
      }
    }
  }
}

.progress-bar__inner {
  position: relative;
  display: block;
  height: 100%;
  animation: run-progress 0.3s;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $color-primary;
    background-size: 75px 75px;
    padding: 0.125em 0;
  }
  &--total-paid {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    margin-bottom: 2em;
    align-items: center;
    font-size: 12px;
    @media screen and (max-width: $lg) {
      display: none;
    }
    span {
      color: #666;
      width: max-content;
      font-family: "Roboto Medium";
      justify-content: center;
      display: flex;
      align-items: center;
    }
    .percentage {
      color: $color-extra-grey;
      font-size: 10px;
      margin-left: 0.5em;
    }
  }
}
.no-padding {
  &:after {
    padding: 0;
  }
}
@keyframes run-progress {
  0% {
    width: 0;
  }
}

.no-transactions-history {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    font-size: 12px;
  }
  svg {
    color: $color-tertiary-light;
    font-size: 2em;
    margin-bottom: 0.65em;
  }
}

.no-line {
  &::before {
    width: 0;
  }
}
