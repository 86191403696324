.content-liquidity-pool {
  padding: 2em;
  &__title {
    font-size: 22px !important;
    font-family: "Roboto Medium";
  }
  svg {
    margin: auto;
    display: block;
    color: $color-primary;
    font-size: 0.8em;
    cursor: pointer;
  }
  .setting-pool {
    color: #666666;
    font-size: 1.5em;
  }
  &__pair {
    margin: 1em 0;
    background: #fafafa;
  }
  &__tip {
    padding: 1.5em;
    font-size: 12px;
    font-family: "Roboto";
    background: #f6efff;
    color: #8f4ae7;
    letter-spacing: 1px;
    line-height: 1.2em;
    margin-top: 1.5em;
  }
  &__token {
    // margin: 1em 0;
  }

  &__settings {
    position: absolute;
    right: 23px;
    top: 20px;
    .btn-settings {
      border: none;
      background: none;
    }
  }

  &__popup {
    right: 0;
    width: 270px;
    padding: 1em;
    position: absolute;
    border-radius: 10px;
    background: #f1f1f1;
    z-index: 9;
    transform: scaleY(0);
    opacity: 0;

    &__head {
      &__title1 {
        font-size: 13px !important;
        font-family: "Roboto Medium" !important;
      }
      &__title2 {
        margin-top: 1em;
        margin-bottom: 0.5em;
        display: flex;
        align-items: flex-end;
        svg {
          margin: 0;
          color: #666666;
          margin-left: 0.5em;
        }
        p {
          color: #666666 !important;
          font-size: 11px !important;
        }
        .setting-multihops {
          button {
            border: none;
            color: #222;
            cursor: pointer;
            font-size: 13px;
            margin: 0 0.3em;
            padding: 5px 10px;
            border-radius: 30px;
            background: $color-contrast;
          }
        }
      }
    }
    &__options {
      display: flex;
      button {
        border: none;
        color: #222;
        cursor: pointer;
        font-size: 13px;
        margin: 0 0.3em;
        padding: 5px 10px;
        border-radius: 30px;
        background: $color-contrast;
      }
      .input-custom-tolerance {
        position: relative;
        input {
          width: 80px;
          border: none;
          text-align: right;
          padding: 5px 10px;
          border-radius: 30px;
          background: $color-contrast;
          padding-right: 23px;
        }
        span {
          top: 5px;
          right: 8px;
          color: #222;
          font-size: 14px;
          position: absolute;
        }
      }
    }
    &__minutes {
      margin-bottom: 1em;
      .input-custom-deadline {
        width: 80px;
        border: none;
        text-align: right;
        padding: 5px 10px;
        border-radius: 30px;
        background: $color-contrast;
      }
      .input-custom-deadline {
        width: 50px;
      }
      label {
        font-size: 11px;
        margin-left: 10px;
        color: #666666;
      }
    }
  }
  .active-button {
    color: $color-contrast !important;
    background: $color-primary !important;
  }

  &__card-token {
    display: flex;
    flex-direction: column;
    padding: 1em;
  }
  &__values {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 1em;
    span {
      right: 0;
      top: -17px;
      font-size: 13px;
      color: #d80000;
      position: absolute;
      font-family: "Roboto";
    }
  }
  .error-field {
    border-color: $color-danger;
  }
  .list-tokens {
    left: 0;
    right: 0;
    z-index: 9;
    padding: 5px;
    margin-top: 5px;
    overflow-y: auto;
    max-height: 170px;
    position: absolute;
    border-radius: 10px;
    background: white;
    top: calc(100% - 5px);
    transform: scaleY(0);
    transform-origin: 10% 20%;
    border: 1px solid #c5c5c5;
    padding-right: 27px;

    opacity: 0;
    ul {
      text-align: left;
      padding-left: 0.6em;
      li {
        display: flex;
        cursor: pointer;
        padding: 0.3em 0;
        align-content: center;
      }
    }
  }

  &__show-tokens {
    position: relative;
    width: 40%;
    height: 40px;
    padding: 0 1em;
    display: flex;
    font-size: 16px;
    align-items: center;
    border-radius: 10px;
    margin-right: 1em;
    background: $color-contrast;
    justify-content: flex-start;
    border: 1px solid #c5c5c5;
    img {
      width: 20px;
      margin-right: 1em;
    }
    button {
      display: flex;
      font-size: 16px;
      justify-content: flex-start;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      height: 40px;
      background: none;
      border: none;
    }

    svg {
      color: #c5c5c5;
      font-size: 1.2em;
      position: absolute;
      right: 7px;
    }
  }
  .balance-liquidity {
    font-size: 13px;
    color: #666666;
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      color: #222;
      margin-left: 0.5em;
    }
  }
  &__amount-token {
    width: calc(60% - 20px);
    position: relative;
    input {
      outline: none;
      height: 40px;
      width: 100%;
      color: #222;
      border-radius: 10px;
      border: 1px solid #c5c5c5;
      text-align: right;
      padding: 0 0.5em;
      font-size: 20px;
      font-family: "Roboto Medium";
    }
    small {
      border-radius: 10px;
      font-family: "Roboto";
      margin-left: 0.5em;
      background: $color-primary;
      color: $color-contrast;
      padding: 0.2em 1em;
      font-size: 10px;
      cursor: pointer;
      right: 0;
      position: absolute;
      bottom: -22px;
    }
  }
  &__price-pool {
    padding: 1em;
    display: flex;
    margin-bottom: 2em;
    text-align: center;
    flex-direction: column;
    border: 1px solid #e5e5e5;
    .content-prices-pool {
      display: flex;
      justify-content: space-between;
    }
    p {
      font-size: 13px;
      margin-bottom: 1em;
      font-family: "Roboto Medium";
    }
    &--1 {
      display: flex;
      flex-direction: column;
    }
    &--2 {
      display: flex;
      flex-direction: column;
    }
    &--3 {
      display: flex;
      flex-direction: column;
    }

    span {
      font-size: 14px;
      color: #222222;
      color: $color-dark;
      font-family: "Roboto Medium";
    }
    small {
      font-size: 12px;
      color: #666666;
      margin-top: 0.2em;
      font-family: "Roboto Medium";
    }
  }
  .btn-liquidity-pool {
    width: 60%;
    display: block;
    margin: 1em auto auto;
  }
}
