.btn {
  border: none;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.2s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 1.5rem;
  }
}
.btn-primary {
  color: $color-contrast;
  background: $color-primary;
  &:hover {
    background: $color-primary-light;
  }
}
.btn-secondary {
  color: $color-contrast;
  background: $color-secondary;
  &:hover {
    background: $color-secondary-light;
  }
}
.btn-round {
  border-radius: 30px;
}
.btn-small {
  font-size: 0.8rem;
  font-family: "Roboto Light";
}
.btn-default {
  padding: 0.4em 1em;
  font-size: 0.8rem;
  font-family: "Roboto Light";
}
.btn-only-text {
  padding: 0.5rem 1rem;
  background: none;
  margin: 0.5rem 0;
  font-size: 1.2rem;
  color: $color-dark;
  &:hover {
    background: $color-dark-extra-light;
  }
}

a.only-link-button {
  font-size: 1;
  display: inline-block;
  text-align: center;
  padding-bottom: 0;
  color: $color-dark;
  position: absolute;
  right: 0;
  top: 15px;
  right: 35px;
}
a.only-link-button {
  &::after {
    display: block;
    content: "";
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
    border-bottom: solid 3px $color-primary;
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
  }
  &:hover:after {
    transform: scaleX(1);
  }
}
a.only-link-button.active {
  &:after {
    transform: scaleX(1);
  }
}

.button-only-text {
  width: 195px !important;
  font-size: 14px !important;
  background: none !important;
  color: $color-primary !important;
}

.btn-view-actions {
  font-size: 0.9rem !important;
  width: 140px !important;
}

.btn-close {
  position: absolute;
  height: 20px !important;
  width: 20px !important;
  right: 10px;
  top: 13px;
  padding: 0 !important;
  color: #777777;
  svg {
    font-size: 1.1rem !important;
  }
}

.btn-add-contract {
  background: none !important;
  color: $color-primary !important;
  width: 180px !important;
  height: 20px !important;
  font-size: 0.9rem !important;
  font-family: "Roboto Medium" !important;
  margin: auto !important;
  border-bottom: 1px solid $color-primary !important;
  border-radius: 0 !important;
  width: auto !important;
  padding: 0 !important;
  svg {
    font-size: 1rem !important;
    right: -25px !important;
  }
}

.btn-add-token {
  background: none !important;
  color: $color-primary !important;
  width: 150px !important;
  height: 20px !important;
  font-size: 0.9rem !important;
  margin: 0em auto 2em auto !important;
  svg {
    font-size: 1rem !important;
  }
}
.btn-icon-none {
  button {
    font-size: 0.813rem;
  }
  svg {
    display: none !important;
  }
}

.btn-token-table {
  display: block;
  button {
    background: none !important;
    color: $color-primary !important;
    width: 150px !important;
    height: 20px !important;
    font-size: 0.9rem !important;
    margin: auto !important;
    font-family: "Roboto Medium";
    svg {
      top: 2px;
    }
  }

  svg {
    font-size: 1rem !important;
    opacity: 1 !important;
  }
}

.btn-text-underline {
  color: #777777 !important;
  background: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  height: auto !important;
  width: auto !important;
  border-bottom: 1px solid #777777 !important;
}

.btn-dragable {
  height: 16px;
  width: 25px;
  background: none;
  margin: 0;
  margin-left: 0.4em;
  border: 1px solid #777777;
  border-radius: 0;
  display: flex;
  justify-content: center;
  color: #777777;
  align-items: center;
  font-size: 2em;
}

.btn-load-more {
  width: auto !important;
  padding: 0 !important;
  color: $color-primary !important;
  background: none !important;
  font-size: 11px !important;
  height: 16px !important;
  margin: auto;
  margin-top: 1em;
  border-radius: 0 !important;
  border-bottom: 1px solid $color-primary !important;
  margin-bottom: 0.3em !important;
}

.action-buttons {
  display: flex;
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  justify-content: space-around;
  flex-wrap: wrap;
  &__claim {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 0.3em;
    }
  }
  &__stake {
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      margin-top: 0.3em;
    }
  }
  &__add-liquidity {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-top: 0.3em;
    }
  }
  &__delete {
    button {
      border: none;
      background: none;
      box-shadow: none;
      color: $color-danger;
      &:hover {
        background: none;
      }
      &:active {
        background: none;
      }
      svg {
        font-size: 2.5em;
        transform: rotate(45deg);
      }
    }
  }
}

.button-remove-card {
  &__delete-card {
    display: flex;
    justify-content: flex-end;
    button {
      width: 110px;
      height: auto;
      border: none;
      font-size: 10px;
      background: none;
      box-shadow: none;
      color: $color-danger;
      text-transform: uppercase;
      padding: 0;
      &:hover {
        background: none;
      }
      &:active {
        background: none;
      }
      svg {
        left: -10px;
        font-size: 2em;
        transform: rotate(45deg);
      }
    }
  }
}

.outline-buttons {
  margin-bottom: 3em;
  button {
    height: 50px;
    background: none;
    padding-right: 0;
    padding-left: 5.3em;
    font-family: "Roboto";
    color: $color-tertiary;
    justify-content: flex-start;
    border: 1px solid $color-primary;
    width: 300px;
    margin: auto;
    margin-bottom: 1.5em;
    transition: 0.3s ease all;
    &:hover {
      background: #f9f5ff;
    }
    img {
      position: absolute;
      left: 25px;
      width: 40px;
      height: 40px;
    }
    svg {
      display: none;
    }
  }
}

.u-button-add-manually {
  background: none !important;
  color: $color-primary !important;
  font-family: "Roboto Medium" !important;
  font-size: 14px !important;
  width: auto !important;
  border-bottom: 1px solid $color-primary !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
  text-transform: uppercase !important;
}

.btn-delete-investment {
  right: 10px;
  display: block;
  position: absolute;
  font-size: 1.6em !important;
  position: relative;
  top: -25px;
  svg {
    transform: rotate(45deg);
  }
}

.btn-investment {
  height: 17px !important;
  margin: 0 0.5em !important;
  display: inline-block !important;
  width: auto !important;
  padding: 0 !important;
  font-size: 15px !important;
  border-radius: 0 !important;
  border-bottom: 1px solid $color-primary !important;
}

.header-btn-icon {
  cursor: pointer;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: block;
  background: none;
  margin: 0;
  padding: 0;
  border: 1px solid #f0f0f0;
  margin: 0 0.1em;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    border-radius: none !important;
    color: none !important;
    background: none !important;
  }
}

.top-text {
  top: -20px;
  position: absolute;
  width: max-content;
}
.bottom-text {
  position: absolute;
  bottom: -20px;
  width: max-content;
}

.settings-investment {
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    height: 35px;
    width: 35px;
  }
}

.icon-collapse-proyect {
  background: none;
  color: $color-primary;
  font-size: 2em;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  margin: 0.2em auto 0.4em;
  height: 30px;
}

.btn-see-all {
  width: auto;
  border: none;
  cursor: pointer;
  margin-top: 1em;
  font-size: 12px;
  background: none;
  margin-left: 4.5em;
  display: inline-block;
  color: $color-primary;
  text-decoration: underline;
  font-family: "Roboto Medium";
}

.btn-verify-ownership {
  cursor: pointer;
  background: none;
  color: $color-primary;
  border: none;
  font-size: 1rem;
  margin-bottom: 2em;
  border-bottom: 1px solid $color-primary;
  border-radius: 0;
  padding: 0;
  height: auto;
  margin: 1.5em auto !important;
  display: block;
}

.btn-home-connect {
  font-size: 17px !important;
  font-family: "Roboto" !important;
  height: 43px !important;
  width: 187px !important;
}

.btn-home-connect-header {
  font-size: 14px !important;
  font-family: "Roboto" !important;
  height: 35px !important;
  width: 158px !important;
}

.btn-home-learn-more {
  font-size: 19px !important;
  font-family: "Roboto" !important;
  height: 51px !important;
  width: 343px !important;
  margin: auto !important;
  margin-top: 4em !important;
  svg {
    font-size: 1.8em !important;
  }
}

.btn-cog {
  margin: 0 0.8em !important;
  height: auto !important;
  width: auto !important;
  font-size: 20px !important;
  color: $color-extra-grey !important;
}

.btn-custom-width {
  width: 265px !important;
  padding: 0 1em !important;
  padding-top: 2em !important;
}

.btn-remove-investment {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 1em;
  margin-top: 2em;
  color: $color-primary;
  font-family: "Roboto";
  border-bottom: 1px solid $color-primary;
}

.btn-your-investment {
  width: 205px !important;
  a {
    color: $color-contrast;
  }
}

.edit-company-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 762px) {
  .edit-company-input {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .input-change-name-company {
    width: 100% !important;
    input {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .no-show-smartphone {
    display: none !important;
  }
  .button-add-token {
    margin-bottom: 1em;
  }
}

.btn-top-all {
  width: auto;
  border: none;
  cursor: pointer;
  font-size: 12px;
  background: none;
  margin-left: 2em;
  display: inline-block;
  color: $color-primary;
  font-family: "Roboto Medium";
  &:hover {
    // transform: scale(1.03);
    text-decoration: underline;
  }
}
.btn-see-more-nft {
  width: auto;
  border: none;
  cursor: pointer;
  font-size: 12px;
  background: none;
  margin-left: 2em;
  margin: 1em auto;
  display: inline-block;
  color: $color-primary;
  font-family: "Roboto";
  text-decoration: underline;
}
.total-all-nft {
  font-size: 12px;
  margin-left: 5px;
  color: $color-dark;
  font-family: "Roboto Bold";
}
.btn-see-more {
  display: flex;
  border: none;
  cursor: pointer;
  font-size: 12px;
  margin-top: 1em;
  background: none;
  margin-bottom: 35px;
  color: $color-primary;
  justify-content: center;
  font-family: "Roboto";
  text-decoration: underline;
}
.btn-get-access {
  text-align: left !important;
  display: inline-block !important;
  font-size: 16px;
  color: $color-primary;
  font-family: "Roboto Medium";
  @media screen and(max-width: $lg) {
    margin-left: 15px;
  }
}

.tag-button {
  padding: 3px 7px;
  margin-left: 5px;
  border-radius: 10px;
  font-size: 12px !important;
  font-family: "Roboto Medium" !important;
  background: $color-extra-light-grey !important;
}

.btn-invite-link {
  display: inline-flex;
  font-size: 14px;
  margin-top: 35px;
  align-items: center;
  color: $color-primary;
  font-family: "Roboto Medium";
  transform: scale(1);
  svg {
    margin-right: 10px;
  }
  &:hover {
    transform: scale(1.03);
  }
}

.no-checked {
  color: $color-primary;
  background: $color-primary-f2e8ff;
}

.checked {
  color: $color-tertiary;
  background-color: $color-extra-light-grey;
}
