.badge-outline {
  padding: 0 0.5em;
  background: none;
  font-size: 10px;
  text-align: center;
  color: $color-dark;
  display: inline-block;
  margin-bottom: 0.5em;
  font-family: "Roboto Light";
  &--primary {
    color: $color-primary;
    border: 1px solid $color-primary;
  }
}

.badge-tag {
  padding: 0 0.5em;
  font-size: 10px;
  text-align: center;
  color: $color-dark;
  margin-bottom: 0.5em;
  display: inline-block;
  color: $color-contrast;
  font-family: "Roboto Light";
  &--dark {
    background: $color-dark;
    border: none;
  }
  &--primary {
    background: $color-primary;
  }
}

.state-project {
  display: flex;
  font-size: 10px;
  border-radius: 4px;
  padding: 0.5em;
  margin-top: 0.6em;
  position: absolute;
  bottom: -37px;
  span {
    display: flex;
    align-items: center;
    svg {
      font-size: 1em !important;
      margin-right: 0.3em;
    }
  }
  span:nth-child(1) {
    font-family: "Roboto Medium";
  }
  span:nth-child(2) {
    font-family: "Roboto";
    margin-left: 1em;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 1.3em;
  }
}

.color-danger {
  background: #ffe9e9;
  span:nth-child(1) {
    color: #d80000;
  }
  span:nth-child(2) {
    color: #222;
  }
}

.color-gray {
  background: #f1f1f1;
  span:nth-child(1) {
    color: $color-dark;
  }
  span:nth-child(2) {
    color: $color-dark;
  }
}

.color-success {
  background: #f4ffdb;
  span:nth-child(1) {
    color: $color-extra;
  }
  span:nth-child(2) {
    color: #222;
  }
}
