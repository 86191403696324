.find-contracts {
  display: flex;
  div {
    width: 500px !important;
  }
  input {
    width: 500px !important;
  }
}

.async-job-status {
  overflow: scroll;
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  font-size: 0.9rem;
  max-height: 500px;
  line-height: 1.5em;
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 0);
  background: rgb(19, 19, 19);
  code {
    color: $color-contrast;
    white-space: break-spaces;
  }
}
