.search-main-content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 210px);
  @media screen and (max-width: $xl) {
    min-height: calc(100vh - 180px);
  }
  @media screen and (max-width: 1080px) {
    min-height: calc(100vh - 160px);
  }
  @media screen and (max-width: $lg) {
    min-height: calc(100vh - 90px);
    // padding-top: 65px;
  }
  @media screen and (max-width: $lg) {
    max-width: 100%;
  }
}
.content-min-height {
  min-height: calc(100vh - 190px);
}
.active-bold {
  font-weight: bold;
  font-family: "Roboto Bold";
}
.search-content {
  // flex-grow: 1;

  @media screen and (max-width: $lg) {
    z-index: 9;
  }
  .element-input-search {
    width: 50%;
  }
  .text-results {
    font-size: 16px;
    color: #999999;
    padding: 0 0 0.5em;
    margin: 0 1em;
    font-family: "Roboto Light";
    color: #222222;
    display: inline-block;
    margin-left: 0;
    margin-right: 1.5em;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    @media screen and (max-width: $smartphone) {
      padding-right: 1em;
    }
  }
  .text-description {
    width: 100%;
    font-size: 16px;
    color: #999999;
    padding: 0 0 0.5em;
    font-family: "Roboto";
    margin: 0 1em;
    span {
      font-size: 16px;
      margin: 0 0.2em;
      color: #222222;
      font-family: "Roboto Medium";
      &::after {
        content: ",";
      }
      &:last-child {
        &::before {
          content: "";
        }
        &::after {
          content: ".";
          color: #999999;
          font-family: "Roboto";
        }
      }
    }
    span.no-commas {
      &::after {
        content: ",";
        color: #999999;
        font-family: "Roboto";
      }
      &:nth-last-child(2) {
        &::after {
          content: "";
        }
      }
      &:last-child {
        &::before {
          content: "and";
          color: #999999;
          margin-right: 0.3em;
          font-family: "Roboto";
        }
        &::after {
          content: ".";
          color: #999999;
          font-family: "Roboto";
        }
      }
      .and-text {
        color: #999999;
        margin-right: 0.1em;
      }
    }
    @media screen and (max-width: $smartphone) {
      // display: flex;
      font-size: 14px;
      padding-right: 1em;
      span {
        font-size: 15px;
      }
    }
  }

  .search-header {
    width: 50%;
    &__logo {
      position: relative;
      @media screen and (max-width: $smartphone) {
        display: flex;
        justify-content: center;
      }
      img {
        width: 173px;
      }
      span {
        font-size: 1rem;
        color: #777777;
        margin-left: 0.6em;
        font-style: italic;
        margin-bottom: 4px;
        font-family: "Roboto Light";
        position: absolute;
        right: -105px;
        @media screen and (max-width: $smartphone) {
          position: absolute;
          right: -5px;
          bottom: 0;
        }
      }
    }
    p {
      font-size: 15px;
      margin-top: 0.4em;
      position: relative;
      color: $color-primary;
    }
  }
  .random-phrases {
    margin-top: 4em;
    margin-bottom: 3em;
    P {
      font-size: 1rem;
      line-height: 1.8em;
      color: #777777;
      text-align: center;
    }

    .pray-emoji {
      &::after {
        content: "\1F64F";
      }
    }
    .voice-emoji {
      &::after {
        content: "\1F5E3";
      }
    }
    .eye-emoji {
      &::after {
        content: "\1F440";
      }
    }
    .glasses-emoji {
      &::after {
        content: "\1F913";
      }
    }
  }

  .search-smart-contract {
    &__search-bar {
      justify-content: flex-end;
    }
    &__input {
      position: relative;
      svg {
        position: absolute;
        font-size: 1.5em;
        color: #c4c4c4;
        right: 20px;
        top: 9px;
      }
      div {
        font-size: 13px;
        right: 17px;
        top: 13px;
        position: absolute;
        color: $color-primary;
        font-family: "Roboto Bold";
        display: none;
        @media screen and (max-width: 700px) {
          display: block;
        }
      }
    }
    span {
      top: -5px;
      width: 55px;
      height: 55px;
      display: flex;
      color: $color-contrast;
      position: relative;
      margin-right: 20px;
      border-radius: 50%;
      align-items: center;
      background: $color-primary;
      justify-content: center;
      font-size: 24px;
      small {
        top: 4px;
        font-size: 13px;
        position: relative;
        margin-left: 0.3em;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    input {
      width: 420px;
      height: 42px;
      border-radius: 20px;
      border: 1px solid #999;
      font-size: 1rem;
      outline: none;
      padding-right: 3em;
      padding-left: 1.5em;
      @media screen and (max-width: 700px) {
        width: 500px;
        padding-right: 3em;
      }
      @media screen and (max-width: $smartphone) {
        width: 400px;
      }
      @media screen and (max-width: 440px) {
        width: 300px;
      }
    }
    &__search {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    button {
      margin-top: 3em;
      width: 300px;
    }
    a {
      margin-top: 1em;
      color: $color-primary;
      text-decoration: underline;
    }
  }
  footer {
    width: 100%;
    margin-top: 8em;
    @media screen and (max-width: $smartphone) {
      margin-top: 3em;
    }
    .links-search-footer {
      margin: 3em 0 1em;
      display: flex;
      justify-content: center;
      a {
        margin: 0 3em;
        color: $color-primary;
        font-family: "Roboto Medium";
      }
    }
    p {
      padding: 1em 0;
      color: #777777;
      font-size: 12px;
      text-align: center;
    }
  }
  .complete-form {
    form {
      display: flex;
      flex-direction: column;
      padding: 0 1em;
      input {
        margin: 1em 0;
        border-radius: 20px;
        &::placeholder {
          color: #c5c5c5;
        }
      }
      @media screen and (max-width: 420px) {
        input {
          width: 300px;
          margin: 1em auto;
          display: block;
        }
        button {
          width: 300px;
          margin: 0.5em auto !important;
          display: block;
        }
      }
    }
  }
  .link-more-questions {
    margin: auto;
    display: block;
    text-align: center;
    color: $color-primary;
    text-decoration: underline;
  }
}

.element-input-search {
  opacity: 1;
  transition: 0.4s ease all;
  transform: translateY(0px);
}
.transition-box {
  opacity: 0;
  transition: 0.4s ease all;
  transform: translateY(50px);
}

// Search page

.search-result {
  display: flex;
  margin-top: 5em;
  align-items: center;
  justify-content: center;
  p {
    font-size: 2em;
    color: $color-primary;
    text-align: center;
    margin-bottom: 0.5em;
  }
  &__input {
    position: relative;
    margin: 1em 0;
    svg {
      top: 10px;
      left: 10px;
      font-size: 1.2rem;
      position: absolute;
      color: $color-primary;
    }
    input {
      padding-left: 3em;
      padding-right: 1em;
      font-size: 1em;
      height: 40px;
      width: 450px;
      border-radius: 30px;
      border: 1px solid #777777;
    }
  }
  button {
    margin: 1em auto;
  }
}

// Seach results
.search-results {
  &__total-results {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 20px !important;
    font-family: "Roboto Medium";
    span {
      margin-left: 1.5em;
      color: #999999;
      font-size: 15px !important;
    }
  }
}

// For search page

.search-list {
  @media screen and (max-width: $lg) {
    // position: relative;
  }
  .contact-rows {
    justify-content: space-between;
  }
  &__item {
    display: flex;
    padding: 25px 0;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    span {
      display: block;
    }
    &__number {
      width: 90px;
      color: #999999;
      padding: 0em 1em 0 1em;
      span {
        font-size: 15px;
      }
      @media screen and (max-width: 768px) {
        width: 70px;
        span {
          font-size: 15px;
        }
      }
    }
    .item-top-list {
      width: auto;
      padding: 0;
      small {
        top: -2px;
        margin: 0 1em;
        position: relative;
        @media screen and (max-width: 420px) {
          margin-left: 0;
        }
      }
    }
    .token-symbol-content {
      display: flex;
      width: 210px;
      align-items: center;
      justify-content: flex-start;
      word-break: break-all;
      margin-left: 1.3em;
      .logo-token {
        width: 19px;
        height: 19px;
        min-width: 19px;
      }
      .logo-symbol-token {
        font-size: 15px;
        margin-right: 60px;
        margin-left: 1.5em;
        color: $color-dark;
        font-family: "Roboto";
      }
      @media screen and (max-width: 775px) {
        margin-left: 0.2em;
      }
      @media screen and (max-width: 768px) {
        width: 40px;
      }
      @media screen and (max-width: $smartphone) {
        margin-left: 0.2em;
        width: 20px;
        .logo-symbol-token {
          margin-right: 0.5em;
          margin-left: 1em;
          width: 20px;
        }
      }
    }
    &__text {
      width: 200px;
      color: #999999;
      padding: 0em 1em 0 0.3em;
      span {
        font-size: 15px;
      }
      @media screen and (max-width: 993px) {
        width: 145px;
        white-space: break-spaces;
        word-break: break-all;
        span {
          font-size: 15px;
        }
      }
    }
    .contract-large-text {
      width: 265px;
      @media screen and (max-width: 590px) {
        width: 125px;
        white-space: break-spaces;
        word-break: break-all;
      }
    }
    &__address-contract {
      width: 160px;
      a {
        color: $color-primary;
        font-size: 14px;
      }
      .amount-smartphone {
        color: #999999;
        font-size: 14px;
        display: none;
        @media screen and (max-width: 993px) {
          width: 100%;
        }
      }
    }
    &__image {
      width: 220px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        margin-left: 1em;
        flex-direction: column;
        width: auto;
        align-items: flex-start;
      }
      a {
        color: $color-primary;
        font-size: 14px;
      }
      img {
        margin-right: 1.2em;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      a.external-link {
        display: flex;
        color: $color-primary;
        margin-left: 0.5em;
      }
      a.copy-address {
        display: flex;
        margin-left: 0.5em;
        color: #777777;
        cursor: pointer;
      }
      a.address-wallet {
        margin-right: 0.3em;
        @media screen and (max-width: 768px) {
          margin-right: 0;
        }
      }
      .amount-smartphone {
        color: #999999;
        font-size: 14px;
        display: none;
        margin-top: 0.2em;
        margin-bottom: 0.3em;
        @media screen and (max-width: 768px) {
          width: 100%;
          display: block;
          text-align: right;
        }
      }
      @media screen and (max-width: 520px) {
        .u-width-holders {
          width: 210px;
        }
      }
    }
    .brain-icon-address {
      width: 190px;
      @media screen and (max-width: $smartphone) {
        width: auto;
      }
    }
    .identicon-smartphone {
      display: none;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
    &__amount {
      width: 180px;
      margin-left: 0;
      font-size: 14px;
      color: #999999;
      text-align: right;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &__hold-token {
      color: #999999;
      width: 180px;
      font-size: 14px;
      margin-left: 0;
      text-align: right;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    &__token-symbol {
      color: $color-extra-grey;
      width: 170px;
      font-size: 15px;
      margin-left: 2em;
      text-align: left;
      @media screen and (max-width: 993px) {
        display: none;
      }
    }
    .amount-large-size {
      width: 220px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .u-width-most-trade {
      text-align: right;
      color: $color-extra-grey;
    }

    @media screen and (max-width: $xl) {
      .u-width-most-trade {
        max-width: 180px;
      }
    }

    &__address {
      width: 220px;
      display: flex;
      align-items: center;
      a {
        font-size: 15px;
        color: $color-primary;
      }
    }
  }
}
.text-activity {
  span:first-child {
    margin-left: 0 !important;
  }
}
.user-emoji {
  &::after {
    content: "\1F464";
  }
}
.using-emoji {
  &::after {
    content: "\1F500";
  }
}
.brain-emoji {
  &::after {
    content: "\1F9E0";
  }
}

.sleeping-emoji {
  &::after {
    content: "\1F634";
  }
}
.active-emoji {
  &::after {
    content: "\1F60E";
  }
}

.fire-emoji {
  &::after {
    content: "\1F525";
  }
}
.normal-emoji {
  &::after {
    content: "\1F642";
  }
}

.explorer-emoji {
  &::after {
    content: "\1F52D";
  }
}
.explorer-emoji {
  &::after {
    content: "\1F52D";
  }
}
.voter-emoji {
  &::after {
    content: "\2696";
  }
}
.collector-emoji {
  &::after {
    content: "\1F391";
  }
}
.dev-emoji {
  &::after {
    content: "\1F527";
  }
}
.artist-emoji {
  &::after {
    content: "\1F5BC";
  }
}
.land-emoji {
  &::after {
    content: "\1F3DD";
  }
}

.holder-emoji {
  &::after {
    content: "\1F634";
  }
}
.defi-emoji {
  &::after {
    content: "\1F4B8";
  }
}
.newcomer-emoji {
  &::after {
    content: "\1F423";
  }
}
.flipper-emoji {
  &::after {
    content: "\1F3B0";
  }
}

.whale-emoji {
  &::after {
    content: "\1F911";
  }
}

.bluechipper-emoji {
  &::after {
    content: "\1F451";
  }
}

.creator-emoji {
  &::after {
    content: "\1F3A8";
  }
}

.search-pagination {
  display: inline-flex;
  margin-top: 2em;
  margin-bottom: 1em;
  color: $color-primary;
  justify-content: center;
  position: relative;
  align-items: flex-end;
  li {
    cursor: pointer;
    a {
      padding: 0.3em 0.5em;
      font-family: "Roboto Light";
      display: block;
    }
  }
  li.next {
    left: -35px;
    position: absolute;
  }
  .selected {
    a {
      padding-bottom: 4px;
      color: $color-dark;
      font-size: 21px;
      font-family: "Roboto";
    }
  }
}
.results-rating {
  margin-top: 2em;
  p {
    color: #999999;
    margin-bottom: 0.5em;
  }
  li {
    display: flex;
  }
  button {
    cursor: pointer;
    height: 40px;
    width: 125px;
    border: none;
    display: flex;
    margin-right: 0.5em;
    align-items: center;
    background: #c5c5c5;
    color: $color-contrast;
    justify-content: center;
    font-size: 1rem;
    position: relative;
    svg {
      position: absolute;
      right: 10px;
      font-size: 1.3rem;
      margin-left: 0.3em;
    }
  }
}

.search-page {
  margin-top: 7em;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: $smartphone) {
    margin-top: 1em;
  }
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .search-header {
    flex-direction: column;
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @media screen and (max-width: $smartphone) {
        display: flex;
        justify-content: center;
      }
      img {
        width: 173px;
      }
      span {
        font-size: 1rem;
        color: #777777;
        margin-left: 0.6em;
        font-style: italic;
        margin-bottom: 4px;
        font-family: "Roboto Light";
        position: absolute;
        right: -105px;
        @media screen and (max-width: $smartphone) {
          position: absolute;
          right: -5px;
          bottom: 0;
        }
      }
    }
    p {
      text-align: center;
      font-size: 15px;
      margin-top: 0.4em;
      position: relative;
      color: $color-primary;
    }
  }
  .random-phrases {
    margin-top: 4em;
    margin-bottom: 3em;
    P {
      font-size: 1rem;
      line-height: 1.8em;
      color: #777777;
      text-align: center;
    }

    .pray-emoji {
      &::after {
        content: "\1F64F";
      }
    }
    .voice-emoji {
      &::after {
        content: "\1F5E3";
      }
    }
    .eye-emoji {
      &::after {
        content: "\1F440";
      }
    }
    .glasses-emoji {
      &::after {
        content: "\1F913";
      }
    }
  }
  .active-emoji {
    &::after {
      content: "\1F60E";
    }
  }
  .search-smart-contract {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__search-bar {
      justify-content: center;
    }
    &__input {
      position: relative;
      svg {
        position: absolute;
        font-size: 1.5em;
        color: #c4c4c4;
        left: 20px;
        top: 9px;
      }
      div {
        font-size: 13px;
        right: 17px;
        top: 13px;
        position: absolute;
        color: $color-primary;
        font-family: "Roboto Bold";
        display: none;
        @media screen and (max-width: 700px) {
          display: block;
        }
      }
    }
    span {
      top: -5px;
      width: 55px;
      height: 55px;
      display: flex;
      color: $color-contrast;
      position: relative;
      margin-right: 20px;
      border-radius: 50%;
      align-items: center;
      background: $color-primary;
      justify-content: center;
      font-size: 24px;
      small {
        top: 4px;
        font-size: 13px;
        position: relative;
        margin-left: 0.3em;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    input {
      width: 565px;
      height: 42px;
      border-radius: 20px;
      border: 1px solid #999;
      font-size: 1rem;
      outline: none;
      padding: 0 2em;
      padding-left: 3.3em;
      @media screen and (max-width: 700px) {
        width: 500px;
        padding-right: 3em;
      }
      @media screen and (max-width: $smartphone) {
        width: 400px;
      }
      @media screen and (max-width: 440px) {
        width: 300px;
      }
    }
    &__search {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    button {
      margin-top: 3em;
      width: 300px;
    }
    a {
      text-align: center;
      display: block;
      margin-top: 1em;
      margin-left: auto;
      margin-right: auto;
      color: $color-primary;
      text-decoration: underline;
    }
  }
  footer {
    width: 100%;
    margin-top: 8em;
    @media screen and (max-width: $smartphone) {
      margin-top: 3em;
    }
    .links-search-footer {
      margin: 3em 0 1em;
      display: flex;
      justify-content: center;
      a {
        margin: 0 3em;
        color: $color-primary;
        font-family: "Roboto Medium";
      }
    }
    p {
      padding: 1em 0;
      color: #777777;
      font-size: 12px;
      text-align: center;
    }
  }
  .complete-form {
    form {
      display: flex;
      flex-direction: column;
      padding: 0 1em;
      input {
        margin: 1em 0;
        border-radius: 20px;
        &::placeholder {
          color: #c5c5c5;
        }
      }
      @media screen and (max-width: 420px) {
        input {
          width: 300px;
          margin: 1em auto;
          display: block;
        }
        button {
          width: 300px;
          margin: 0.5em auto !important;
          display: block;
        }
      }
    }
  }
  .link-more-questions {
    margin: auto;
    display: block;
    text-align: center;
    color: $color-primary;
    text-decoration: underline;
  }
}

.loader-results {
  height: 55px;
  padding-top: 10em;
  padding-bottom: 5em;
}

.search-total-holder {
  &__item {
    margin-top: 2em;
    &__image {
      display: flex;
      align-items: center;
    }
  }
  .external-link {
    color: $color-primary;
    margin-left: 0.5em;
  }
  .copy-address {
    margin-left: 0.5em;
    color: #999999;
    cursor: pointer;
  }
  a {
    color: #777777;
    margin-left: 0.5em;
  }
}

.search-component {
  width: 100%;
  // height: calc(100vh - 310px);
  display: flex;
  @media screen and (max-width: $lg) {
    align-items: normal;
    justify-content: center;
  }
  &__search-bar {
    width: 80%;
    margin: 0 auto 0;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: $lg) {
      margin: 0 auto 4em;
      margin-top: 2em;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0 auto 3em;
    }
    img {
      width: 173px;
      margin-bottom: 30px;
      @media screen and (max-width: $lg) {
        width: 183px;
        margin-bottom: 48px;
      }
    }
    form {
      width: 100%;
      @media screen and (max-width: 1024px) {
        margin-top: 0;
      }
      button {
        width: 91px;
        height: 33px;
        color: #222222;
        background: #f1f1f1;
        font-family: "Roboto";
        font-size: 14px;
        margin-top: 3em;
        @media screen and (max-width: $lg) {
          display: none;
        }
        &:hover {
          border: 1px solid #b38ae8;
          background: #f1f1f1;
        }
        &:active {
          border: 1px solid #b38ae8;
          background: #f1f1f1;
        }
      }
    }
  }
  &__search {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .MuiAutocomplete-root {
    width: fit-content;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &__input {
    width: 415px;
    margin: auto;
    position: relative;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    svg {
      position: absolute;
      font-size: 1.1rem;
      color: #b38ae8;
      right: 25px;
      top: 16px;
      @media screen and (max-width: $lg) {
        top: 14px !important;
        right: 14px;
        font-size: 1rem;
        z-index: 1;
      }
    }
  }
  span {
    top: -5px;
    width: 55px;
    height: 55px;
    display: flex;
    color: $color-contrast;
    position: relative;
    margin-right: 20px;
    border-radius: 50%;
    align-items: center;
    background: $color-primary;
    justify-content: center;
    font-size: 24px;
    small {
      top: 4px;
      font-size: 13px;
      position: relative;
      margin-left: 0.3em;
    }
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  input {
    width: 100% !important;
    height: 45px;
    outline: none !important;
    font-size: 16px;
    color: #222222;
    border-radius: 999px;
    padding: 0 !important;
    padding-left: 25px !important;
    padding-right: 4.5em !important;
    border: 1px solid #dcdcdc !important;
    &::placeholder {
      color: #c4c4c4;
      opacity: 1;
    }
    &:focus {
      border-color: #b38ae8 !important;
    }
    @media screen and(max-width: $lg) {
      height: 42px;
      padding-right: 3.5em;
      padding-left: 18px !important;
    }
  }

  .clean-word {
    top: 16px;
    right: 50px;
    font-size: 1rem;
    color: #cacaca;
    cursor: pointer;
    @media screen and(max-width: $lg) {
      top: 12px;
      right: 35px;
    }
  }

  .back-search {
    display: none;
  }
  // @media screen and (max-width: $lg) {
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   height: auto;
  //   position: fixed;
  //   background: white;
  //   &__search-bar {
  //     width: 100%;
  //     margin-top: 0 !important;
  //     form {
  //       margin-top: 0;
  //       padding: 15px 70px 0 15px;
  //     }
  //   }
  //   .logo-search {
  //     display: none;
  //   }
  //   .isotype-logo {
  //     left: 15px;
  //     top: 10px;
  //     width: 20px;
  //     display: block;
  //     position: absolute;
  //   }

  //   input {
  //     padding-left: 3em;
  //   }
  //   .back-search {
  //     top: 8px;
  //     width: 20px;
  //     right: -40px;
  //     display: block;
  //     position: absolute;
  //   }
  // }
}
.MuiInput-underline::after {
  border-bottom: none !important;
  border-bottom: 0 !important;
}
.MuiInput-underline::hover {
  border-bottom: none !important;
}
.MuiInput-underline::before {
  display: none;
}
.MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: none !important;
  display: none !important;
}
.isotype-logo {
  display: none;
}
.isotype-logo-link {
  top: 11px;
  z-index: 99999;
  position: absolute;
}

.search-component__search {
  .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0 !important;
  }
  .MuiIconButton-label {
    display: none !important;
  }
  .MuiAutocomplete-clearIndicator {
    display: none !important;
  }
}
.search-activity {
  margin-top: 0;
  &__item {
    &__token {
      display: flex;
      align-items: center;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      img {
        margin-right: 0.5em;
      }
      span {
        color: #777777;
        font-size: 1.1rem;
      }
      a {
        display: block;

        color: $color-primary;
      }
    }
    .external-link {
      display: flex;
      color: $color-primary;
      margin-left: 0.5em;
      align-items: center;
    }
    .copy-address {
      display: flex;
      color: #999999;
      cursor: pointer;
      margin-left: 0.5em;
    }
    &__few-transaction {
      span {
        display: block;
        color: #222222;
        margin-bottom: 0.5em;
      }
      margin-top: 1em;
      ul {
        li {
          height: 40px;
          display: flex;
          align-items: center;
          border-top: 1px solid #c5c5c5;
          a {
            color: #999999;
          }
        }
      }
    }
  }
  .active-status {
    background: $color-extra;
    padding: 0 0.5em;
    margin-left: 1em;
    color: $color-contrast;
    font-family: "Roboto light";
  }
  .dead-status {
    background: $color-danger;
    padding: 0 0.5em;
    margin-left: 1em;
    color: $color-contrast;
    font-family: "Roboto light";
  }
}

.token-usage {
  a {
    color: #777777;
  }
  .text-result {
    margin: 1em 0;
  }
}

.search-box {
  display: none;
}
@media screen and (max-width: $lg) {
  .search-box {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 310px);
    &__image {
      width: 230px;
    }
    &__input-search {
      width: 80%;
      height: 42px;
      margin-top: 2.5em;
      border-radius: 20px;
      border: 1px solid #c5c5c5;
      position: relative;
      svg {
        top: 10px;
        right: 20px;
        font-size: 1.2rem;
        position: absolute;
        color: $color-primary;
      }
    }
  }
}

.search-not-found {
  margin-top: 0;

  @media screen and (max-width: $lg) {
    margin-top: 0;
  }
  p {
    font-size: 14px;
    font-family: "Roboto";
    color: $color-dark;
    text-align: center;
    margin-bottom: 1em;
    margin-top: 5em;
  }
  ul {
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    text-align: center;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      margin-top: 0;
    }
    @media screen and (max-width: 768px) {
      width: auto;
    }
    li {
      small {
        font-family: "Roboto";
        cursor: pointer;
        font-size: 14px;
        color: $color-primary;
      }
      width: 190px;
      padding: 0.6em 0;
      @media screen and (max-width: $xl) {
        padding: 0.3em 0;
      }
    }
  }
}

.search-not-data {
  padding-top: 0.5em;
  @media screen and (max-width: $lg) {
    margin-top: 0;
  }
  p {
    font-size: 15px;
    font-family: "Roboto";
    color: $color-dark;
    text-align: left;
    margin-bottom: 1em;
    margin-top: 1em;
    line-height: 1.8em;
    padding: 0 1rem;
  }
  ul {
    width: 500px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    padding: 0 1rem;
    @media screen and (max-width: 768px) {
      width: auto;
    }
    li {
      text-align: left;
      small {
        font-family: "Roboto";
        cursor: pointer;
        font-size: 15px;
        color: $color-primary;
      }
      width: 190px;
      padding: 0.6em 0;
    }
  }
}

.message-no-result {
  font-size: 15px;
  line-height: 1.4em;
  margin-bottom: 2.5em;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.about-search-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5em;
  min-height: calc(100vh - 210px);
  a {
    display: block;
    text-align: center;
    padding-top: 1em;
    margin-bottom: 4em;
    @media screen and (max-width: $lg) {
      padding-top: 2em;
    }
  }
  img {
    width: 173px;
    @media screen and (max-width: $lg) {
      width: 183px;
    }
  }
  @media screen and(max-width :$smartphone) {
    img {
      width: 137px;
    }
  }
  p {
    color: $color-dark;
    font-family: "Roboto Light";
    @media screen and (max-width: $lg) {
      padding: 0 1em;
      line-height: 1.8em;
    }
  }
}

.height-search-box {
  input {
    // width: 520px !important;
    padding-left: 25px !important;
    @media screen and (max-width: $xl) {
      // width: 400px !important;
    }
    @media screen and (max-width: $lg) {
      padding-left: 2.2em !important;
    }
  }
}

// NFT
.nft {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: $lg) {
    justify-content: center;
  }
  &__image {
    width: 150px;
    height: 150px;
    margin-right: 60px;
    margin-left: 1em;
    img {
      height: 150px;
    }
    @media screen and (max-width: $lg) {
      width: 200px;
      height: 200px;
      margin-right: 0;
      text-align: center;
      margin-left: 0;
      img {
        height: 200px;
      }
    }
  }
  &__content-details {
    width: 400px;
    &--id {
      font-size: 18px;
      margin-bottom: 0.5em;
      font-family: "Roboto medium";
      @media screen and (max-width: $lg) {
        margin-top: 1em;
        margin-bottom: 0.2em;
        text-align: center;
      }
    }
    table {
      width: 100%;
      tbody {
        tr {
          td {
            img {
              width: 15px;
              height: 15px;
            }
            font-size: 14px;
            span {
              display: inline;
            }
            a {
              font-size: 14px;
              color: $color-primary;
            }
            &:first-child {
              width: 100px;

              color: #999;
              font-size: 12px;
            }
            &:nth-child(2) {
              span {
                font-size: 16px;
                color: $color-dark;
                font-family: "Roboto Bold";
              }
              color: #999;
            }
            padding: 0.3em 0;
          }
        }
      }
    }
  }
}

.header-search-list {
  height: 62px;
  display: flex;
  padding-left: 1em;
  color: $color-dark;
  align-items: center;
  background: #fafafa;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  font-size: 15px;
  img {
    width: 23px;

    margin-right: 1em;
  }
}
.similar-tokens {
  margin-left: 9.5em;
  @media screen and (max-width: $lg) {
    margin-left: 2.2em;
  }
}
.similar-projects {
  display: inline-block;
  p {
    display: inline;
    font-size: 12px;
    font-style: italic;
    font-family: "Roboto Light";
    color: $color-dark;
    line-height: 1.6em;
  }
  span {
    color: $color-dark;
    font-size: 12px;
    font-style: italic;
    font-family: "Roboto Light";
  }
  span.add-and {
    color: $color-dark;
    font-size: 12px;
    font-style: italic;
    font-family: "Roboto Light";
    &:last-child {
      &::before {
        content: "and";
        color: $color-dark;
        font-size: 12px;
        font-style: italic;
        font-family: "Roboto Light";
      }
      &::after {
        content: ".";
      }
    }
    &:nth-last-child(2) {
      &::after {
        content: "";
      }
    }
    &::after {
      content: ",";
      color: $color-dark;
    }
  }
  small {
    cursor: pointer;
    font-size: 12px;
    color: $color-primary;
    font-family: "Roboto Light";
  }
}

.table-portfolio {
  min-width: 400px;
  tr {
    td {
      &:first-child {
        text-align: left;
      }
      text-align: right;
      font-size: 15px;
      color: $color-extra-grey;
    }
  }
}
.address-and-balance {
  margin-right: 2em;
  display: flex;
  flex-direction: column;
  span {
    font-size: 14px;
    color: $color-extra-grey;
  }
}

.search-bar-content {
  width: 505px;
  border: 1px solid #c5c5c5 !important;
  height: 48px;
  outline: none !important;
  font-size: 16px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5em;
  color: #949494;
  cursor: pointer;
  p {
    font-family: "Roboto Light";
  }
  svg {
    color: #b38ae8;
    font-size: 1.1rem;
  }
}

.stiky-header {
  top: 0 !important;
  z-index: 999 !important;
  position: sticky !important;
}

.empty-results {
  border: 0 !important;
  padding: 0 !important;
}
