.comming-soon-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 215px);
  @media screen and (max-width: $tablet) {
    margin: 0 40px;
  }
  @media screen and (max-width: $smartphone) {
    margin: 0 25px;
  }
  a {
    margin-bottom: 0 !important;
    text-align: left !important;
    img {
      width: 167px;
    }
  }
  .comming-soon-text {
    font-size: 14px;
  }
  &__content {
    margin-top: 5em;
    &--title {
      margin-bottom: 5px;
      font-size: 20px !important;
      @media screen and (max-width: $smartphone) {
        font-size: 16px !important;
      }
    }
    &--description {
      width: 413px;
      font-size: 1rem;
      line-height: 23px;
      margin-bottom: 2.2em;
      @media screen and (max-width: $smartphone) {
        width: 280px;
        font-size: 14px !important;
      }
    }
    &--link {
      height: 35px;
      padding: 0 15px;
      font-size: 14px;
      border-radius: 30px;
      align-items: center;
      display: inline-flex;
      color: $color-contrast;
      background: $color-primary;

      svg {
        font-size: 16px;
        margin-right: 10px;
      }
    }
  }
}

.footer-fixed {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;

  margin-top: 3em;
  text-align: center;
  padding-bottom: 1.5em;
  p {
    margin-top: 1em;
    font-size: 12px;
    color: $color-dark-dark777;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 0.5em 0;
    padding-bottom: 1em;
    background: $color-contrast;
    p {
      margin-top: 1em;
      font-size: 10px;
    }
  }
}

.comming-soon-page + footer {
  display: none !important;
}
